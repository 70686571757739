import React, { useCallback, useContext } from "react"

import { Hidden } from "../../../_common"
import FiltersContext from "../context/FiltersContext"
import { FiltersActions } from "../reducers/filtersReducer"
import { useFilterByIdSelector } from "../selectors/filterByIdSelector"

import { ExpandedRadioFilter } from "./ExpandedRadioFilter"
import { RadioFilter } from "./RadioFilter"
import { SelectFilter } from "./SelectFilter"
import { SliderFilter } from "./SliderFilter"
import { ButtonGroupFilter } from "./ButtonGroupFilter"
import { FiltersModuleFieldId, IExpertsFilter } from "../../../../types"

export const CommonFilter: React.FC<Pick<IExpertsFilter, "id">> = ({ id }) => {
  const {
    state,
    dispatch,
    layoutSettings: { isFullMode, isLimitedMode },
  } = useContext(FiltersContext)

  const { filter } = useFilterByIdSelector(state, id)

  const handleFilterChange = useCallback(
    (e: any) => {
      const { value } = e.target

      dispatch({
        type: FiltersActions.UPDATE_FILTER_ACTIVE_OPTION,
        payload: {
          id,
          activeOption: value as string,
        },
      })
    },
    [dispatch, id]
  )

  const handleButtonGroupChange = useCallback(
    (value: any) => {
      dispatch({
        type: FiltersActions.UPDATE_FILTER_ACTIVE_OPTION,
        payload: {
          id,
          activeOption: value as string,
        },
      })
    },
    [dispatch, id]
  )

  const handleSortingChange = useCallback(
    (e: any) => {
      const { value } = e.target

      dispatch({
        type: FiltersActions.UPDATE_SORTING_ACTIVE_OPTION,
        payload: {
          activeOption: value as string,
        },
      })
    },
    [dispatch]
  )

  switch (id) {
    case FiltersModuleFieldId.PRICE: {
      return <SliderFilter filter={filter} />
    }

    case FiltersModuleFieldId.LANGUAGE: {
      return isFullMode ? (
        <>
          <Hidden mdUp>
            <ExpandedRadioFilter
              filter={filter}
              handleFilterValueChange={handleFilterChange}
            />
          </Hidden>
          <Hidden mdDown>
            <SelectFilter
              filter={filter}
              handleFilterValueChange={handleFilterChange}
              variant="outlined"
            />
          </Hidden>
        </>
      ) : (
        <SelectFilter
          filter={filter}
          handleFilterValueChange={handleFilterChange}
          variant="standard"
        />
      )
    }

    case FiltersModuleFieldId.SORTING: {
      return (
        <>
          <Hidden mdUp>
            <ExpandedRadioFilter
              filter={filter}
              handleFilterValueChange={handleSortingChange}
            />
          </Hidden>
          <Hidden mdDown>
            <SelectFilter
              filter={filter}
              variant="outlined"
              handleFilterValueChange={handleSortingChange}
            />
          </Hidden>
        </>
      )
    }

    default: {
      if (isLimitedMode) {
        if (id === FiltersModuleFieldId.PRODUCT_TYPE) {
          return (
            <ButtonGroupFilter
              filter={filter}
              handleFilterValueChange={handleButtonGroupChange}
            />
          )
        }

        return (
          <SelectFilter
            filter={filter}
            handleFilterValueChange={handleFilterChange}
          />
        )
      }

      return (
        <>
          <Hidden mdUp>
            <ExpandedRadioFilter
              filter={filter}
              handleFilterValueChange={handleFilterChange}
            />
          </Hidden>
          <Hidden mdDown>
            <RadioFilter
              filter={filter}
              handleFilterValueChange={handleFilterChange}
            />
          </Hidden>
        </>
      )
    }
  }
}
