"use client"

import React, { ReactNode } from "react"
import classes from "./FullDOM.module.scss"
import { getRuntimeConfig } from "../../utils/runtimeConfig"
import LiteYTPlayer from "./LiteYTPlayer"

interface IFullDomBaseProps {
  html: string
  sys: { id: string }
  children?: ReactNode
}

const { BASE_URL } = getRuntimeConfig()

const idPrefix = "q" // in CSS id should start from symbol, not a number

const FullDOMBase: React.FC<IFullDomBaseProps> = ({ html, sys, children }) => {
  const newHTML = html.replace(/href="(?<yourlink>\/[^"]{0,})"/g, function (
    _m,
    $1
  ) {
    return `href="${BASE_URL?.slice(0, -1)}${$1}"`
  })

  const shouldIncludeLiteYTLib = newHTML.includes("<lite-youtube")

  return (
    <>
      {children}

      {shouldIncludeLiteYTLib ? (
        <LiteYTPlayer html={newHTML} id={`${idPrefix}${sys.id}`} />
      ) : (
        <div
          className={classes.fullDom}
          id={`${idPrefix}${sys.id}`}
          dangerouslySetInnerHTML={{ __html: newHTML }}
        />
      )}
    </>
  )
}

export default FullDOMBase
