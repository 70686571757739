"use client"

import React from "react"
import {
  BLOCKS,
  MARKS,
  Document,
  INLINES,
  Block,
  Inline,
  Text,
} from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import { Button, AdviqoLink, Typography, NextImage } from "../_common"
import { ContentfulRichText, ItemViewOptions } from "../../types"
import sentryUtils from "../../utils/sentryUtils"
import { buildLinkUrl } from "../../utils/slugUtils"
import ContentItemListComponent from "../../components/ContentItemListComponent"
import HoroscopeContentComponent from "../../components/HoroscopeContentComponent"
import ContentItemComponent from "../../components/ContentItemComponent"
import { useBrowserFeatures } from "../../hooks/browserFeatures"
import FullDOM from "../FullDOM"
import { useGetAssetQuery } from "../../graphql/contentfulHooks"
import { IImageFormatContentful } from "../../graphql/contentful-schema"
import ExpertsListingWithFiltersComponent from "../../components/ExpertsListingWithFiltersComponent"
import classes from "./RichText.module.scss"

interface IRichTextProps {
  sys?: { id: string }
  content: ContentfulRichText
  isPrintable?: boolean | null
  isTextAlignInherited?: boolean
}

function getEmbeddedEntryData(
  node: Block | Inline,
  links: Required<ContentfulRichText>["links"]
) {
  let nodeType: "inline" | "block"

  switch (node.nodeType) {
    case BLOCKS.EMBEDDED_ENTRY:
      nodeType = "block"
      break
    case INLINES.EMBEDDED_ENTRY:
      nodeType = "inline"
      break

    default:
      throw new Error(`RichText. Unknown nodeType: '${node.nodeType}'`)
  }

  const result = links.entries?.[nodeType]?.find((item) => {
    return item?.sys?.id === node.data?.target?.sys?.id
  })

  if (!result) {
    console.warn(
      `RichText. Cannot extract data for "${node.nodeType}" entry with id "${
        node.data?.target?.sys.id
      }" from links. Most likely data for this node isn't fetched. Check grapqhl request. \nEntry: ${JSON.stringify(
        node,
        null,
        2
      )}.\nLinks has no data for this entry.\nLinks:\n${JSON.stringify(
        links?.entries?.[nodeType],
        null,
        2
      )}.`
    )
  }

  return result
}

const PrintButton = () => (
  <Button
    variant="contained"
    size="small"
    color="primary"
    onClick={() => window.print()}
  >
    PRINT
  </Button>
)

const renderRichText = (
  richTextDocument: Document,
  isTextAlignInherited?: boolean,
  links?: ContentfulRichText["links"]
) => {
  const textAlign = isTextAlignInherited ? "inherit" : undefined

  const Text: React.FC<{ children: React.ReactNode }> = ({ children }) => (
    <Typography variant="body2" component="p" align={textAlign}>
      {children}
    </Typography>
  )
  const H1: React.FC<{ children: React.ReactNode }> = ({ children }) => (
    <Typography variant="h1" align={textAlign}>
      {children}
    </Typography>
  )
  const H2: React.FC<{ children: React.ReactNode }> = ({ children }) => (
    <Typography className={classes.sameFontSize} variant="h2" align={textAlign}>
      {children}
    </Typography>
  )
  const H3: React.FC<{ children: React.ReactNode }> = ({ children }) => (
    <Typography className={classes.sameFontSize} variant="h3" align={textAlign}>
      {children}
    </Typography>
  )
  const H4: React.FC<{ children: React.ReactNode }> = ({ children }) => (
    <Typography className={classes.sameFontSize} variant="h4" align={textAlign}>
      {children}
    </Typography>
  )
  const H5: React.FC<{ children: React.ReactNode }> = ({ children }) => (
    <Typography className={classes.sameFontSize} variant="h5" align={textAlign}>
      {children}
    </Typography>
  )

  const Bold: React.FC<{ children: React.ReactNode }> = ({ children }) => (
    <Typography component="b" variant="inherit">
      {children}
    </Typography>
  )
  const Italic: React.FC<{ children: React.ReactNode }> = ({ children }) => (
    <Typography component="em">{children}</Typography>
  )
  const Underline: React.FC<{ children: React.ReactNode }> = ({ children }) => (
    <Typography component="u">{children}</Typography>
  )

  const renderEmbeddedEntry = (node: Block | Inline) => {
    if (!node.data.target.sys.id || !links) {
      return null
    }

    let entryData

    try {
      entryData = getEmbeddedEntryData(node, links)
    } catch (e) {
      console.error(e)
      sentryUtils.report({ error: e })
    }

    if (entryData && entryData.__typename === "ContentItem") {
      return (
        <ContentItemComponent
          data={entryData}
          itemViewOptions={ItemViewOptions.IN_ARTICLE}
        />
      )
    }
    if (entryData && entryData.__typename === "ContentItemList") {
      return <ContentItemListComponent data={entryData} />
    }
    if (entryData && entryData.__typename === "HoroscopeContent") {
      return <HoroscopeContentComponent data={entryData} />
    }
    if (entryData && entryData.__typename === "ExpertsListingWithFilters") {
      return <ExpertsListingWithFiltersComponent data={entryData} />
    }

    if (entryData && entryData.__typename === "FullDom") {
      return <FullDOM {...entryData} />
    }

    return null
  }

  const options = {
    renderMark: {
      [MARKS.BOLD]: (text: React.ReactNode) => <Bold>{text}</Bold>,
      [MARKS.ITALIC]: (text: React.ReactNode) => <Italic>{text}</Italic>,
      [MARKS.UNDERLINE]: (text: React.ReactNode) => (
        <Underline>{text}</Underline>
      ),
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (
        _node: Block | Inline,
        children: React.ReactNode
      ) => {
        return <Text>{children}</Text>
      },
      [BLOCKS.EMBEDDED_ASSET]: (node: Block | Inline) => {
        if (!node.data.target.sys.id) {
          return null
        }
        const { isFeatureSupported } = useBrowserFeatures()
        const { error, data } = useGetAssetQuery({
          variables: {
            id: node.data.target.sys.id,
            format: isFeatureSupported("webp")
              ? IImageFormatContentful.WebpContentful
              : IImageFormatContentful.JpgContentful,
          },
        })

        if (error) {
          sentryUtils.report({ message: `Fetch Asset Error`, error })
        }

        if (!data) {
          return null
        }

        return (
          data.asset &&
          data.asset.url && (
            <div className={classes.image}>
              <NextImage
                src={data.asset.url}
                width={data.asset.width}
                height={data.asset.height}
                targetHeight={data.asset.height}
                alt="asset"
              />
            </div>
          )
        )
      },
      [INLINES.EMBEDDED_ENTRY]: renderEmbeddedEntry,
      [BLOCKS.EMBEDDED_ENTRY]: renderEmbeddedEntry,
      [BLOCKS.HEADING_1]: (
        _node: Block | Inline,
        children: React.ReactNode
      ) => <H1>{children}</H1>,
      [BLOCKS.HEADING_2]: (
        _node: Block | Inline,
        children: React.ReactNode
      ) => <H2>{children}</H2>,
      [BLOCKS.HEADING_3]: (
        _node: Block | Inline,
        children: React.ReactNode
      ) => <H3>{children}</H3>,
      [BLOCKS.HEADING_4]: (
        _node: Block | Inline,
        children: React.ReactNode
      ) => <H4>{children}</H4>,
      [BLOCKS.HEADING_5]: (
        _node: Block | Inline,
        children: React.ReactNode
      ) => <H5>{children}</H5>,
      [BLOCKS.HEADING_6]: (
        _node: Block | Inline,
        children: React.ReactNode
      ) => (
        <Typography
          className={classes.sameFontSize}
          variant="h6"
          align={textAlign}
        >
          {children}
        </Typography>
      ),
      [BLOCKS.QUOTE]: (_node: Block | Inline, children: React.ReactNode) => (
        <Typography component="blockquote">{children}</Typography>
      ),
      [BLOCKS.HR]: () => <hr />,
      [BLOCKS.OL_LIST]: (_node: Block | Inline, children: React.ReactNode) => (
        <Typography component="ol">{children}</Typography>
      ),
      [BLOCKS.UL_LIST]: (_node: Block | Inline, children: React.ReactNode) => (
        <Typography component="ul">{children}</Typography>
      ),
      [BLOCKS.LIST_ITEM]: (
        _node: Block | Inline,
        children: React.ReactNode
      ) => <Typography component="li">{children}</Typography>,
      [INLINES.HYPERLINK]: (node: Block | Inline) => {
        const {
          data: { uri = "" },
        } = node
        const { content } = node

        const target =
          uri.startsWith("http") || uri.startsWith("www") ? "_blank" : "_self"

        return (
          <AdviqoLink to={uri} target={target} className={classes.link}>
            {(content[0] as Text).value}
          </AdviqoLink>
        )
      },
      [INLINES.ENTRY_HYPERLINK]: (node: Block | Inline) => {
        const {
          data: {
            target: {
              sys: { id },
            },
          },
        } = node
        const { content } = node
        const title = (content[0] as Text).value
        const linkData = links?.entries.hyperlink.find((item) => {
          return item.sys.id === id
        })

        if (!linkData) {
          return title
        }

        const to = buildLinkUrl(linkData, id)

        return (
          <AdviqoLink to={to} className={classes.link}>
            {title}
          </AdviqoLink>
        )
      },
    },
    renderText: (text: string) => {
      return text
        .split("\n")
        .reduce((children: React.ReactNode[], textSegment: string, index) => {
          return [...children, index > 0 && <br key={index} />, textSegment]
        }, [])
    },
  }

  const source = documentToReactComponents(richTextDocument, options)

  return <>{source}</>
}

const RichText: React.FC<IRichTextProps> = ({
  content,
  isPrintable,
  isTextAlignInherited,
}) => {
  return (
    <>
      {isPrintable && <PrintButton />}
      {renderRichText(content.json, isTextAlignInherited, content.links)}
    </>
  )
}

export default RichText
