import { useRef, useEffect } from "react"

export const useSkipInitialRender = (): boolean => {
  const isMountRef = useRef(false)

  useEffect(() => {
    isMountRef.current = true
  }, [])

  return isMountRef.current
}
