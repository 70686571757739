import React from "react"
import { useTranslations } from "next-intl"

import type { AutocompleteRenderInputParams } from "@mui/material"

import { CircularProgress, IconButton, SearchIcon } from "../../../_common"
import Input from "../../../Auth/components/Input"
import { SearchSuggestions } from "../../../Search"
import classes from "./SearchWithSuggestions.module.scss"

interface ISearchWithSuggestionsProps {
  onSubmit: (term: string) => void
}

const SearchWithSuggestions: React.FC<ISearchWithSuggestionsProps> = ({
  onSubmit,
}) => {
  const translate = useTranslations()

  return (
    <SearchSuggestions
      id="filter"
      formClassName={classes.searchForm}
      onSearchSubmit={onSubmit}
      inputComponent={({ inputProps, loading }) => {
        return (
          <>
            <Input
              suggestionProps={inputProps as AutocompleteRenderInputParams}
              type="text"
              label={translate("searchBeraterPlaceHolder")}
              placeholder={translate("searchBeraterPlaceHolder")}
              dataTestId="search-input-autosuggest"
              name="search-input-autosuggest"
              rootClass={classes.rootInput}
            />
            <IconButton
              type="submit"
              className={classes.submitButton}
              aria-label="search"
              data-testid="filters-search-panel-submit-button"
              size="large"
            >
              {loading ? (
                <CircularProgress color="inherit" size={24} />
              ) : (
                <SearchIcon />
              )}
            </IconButton>
          </>
        )
      }}
      containerClassName={classes.container}
      suggestionsDropdownClass={classes.suggestionsDropdownClass}
      inputComponentType="textField"
    />
  )
}

export default SearchWithSuggestions
