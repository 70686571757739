import React from "react"
import clsx from "clsx"

import regExp from "../../../utils/regExp"
import { indicatorMessage } from "../../../consts"
import { TIndicator } from "../../../types"
import classes from "./Indicator.module.scss"

interface IIndicator {
  value: string | undefined
}

function getIndicator(value: string | undefined): TIndicator | null {
  if (!value || !value.length) return null
  if (value.length < 6) return "short"

  if (regExp.letterNumberSpecialChar.test(value)) {
    return value.length >= 8 ? "strong" : "normal"
  }
  if (
    regExp.letterAndNumber.test(value) ||
    regExp.letterAndSpecialChar.test(value) ||
    regExp.numberAndSpecialChar.test(value)
  ) {
    return "normal"
  }
  return "weak"
}

const Indicator: React.FC<IIndicator> = ({ value }) => {
  const indicator = getIndicator(value)
  const barClassNames = clsx(classes.bar, indicator && classes[indicator])
  const messageClassNames = clsx(
    classes.message,
    indicator && classes[indicator]
  )

  return (
    <div className={classes.root}>
      <span className={barClassNames} />
      <span className={barClassNames} />
      <span className={barClassNames} />
      <span className={messageClassNames} data-testid="password-indicator-text">
        {indicator ? indicatorMessage[indicator] : ""}
      </span>
    </div>
  )
}

export default Indicator
