import { ParsedUrlQuery } from "querystring"
import { isNumber } from "../../../utils/utils"
import { DELIMITER } from "./consts"
import {
  FiltersModuleFieldId,
  IExpertsFilter,
  IFilterOption,
  FiltersModuleMode,
  UseTypeOf,
  IAdvisorListingFieldState,
} from "../../../types"
import { Method, Tag, Topic } from "./types"

export function getCategoryNos(method?: Method, topic?: Topic): number[] {
  const categoryNos = []

  if (method && method !== "ALL" && isNumber(method))
    categoryNos.push(Number(method))
  if (topic && topic !== "ALL" && isNumber(topic))
    categoryNos.push(Number(topic))

  return categoryNos
}

export function getFilterByTag(
  tag: Tag
): Partial<Record<"new" | "astro_tv" | "premium", IAdvisorListingFieldState>> {
  switch (tag) {
    case "new":
      return { new: IAdvisorListingFieldState.ON }
    case "astro_tv":
      return { astro_tv: IAdvisorListingFieldState.ON }
    case "premium":
      return { premium: IAdvisorListingFieldState.ON }
    default:
      return {}
  }
}

export const hasValidOptionInQuery = (
  id: FiltersModuleFieldId,
  query: ParsedUrlQuery,
  filterOptions: IFilterOption[]
): boolean => {
  if (id === FiltersModuleFieldId.PRICE) {
    const queryStringValue = query.maxPrice?.toString()

    return isNumber(queryStringValue) && Number(queryStringValue) >= 0
  }

  const queryStringValue = query[id]?.toString()

  return filterOptions.some(
    (op) =>
      op.id.toString() === queryStringValue ||
      (op.children as IFilterOption[] | undefined)?.some(
        (child) => child.id === queryStringValue
      )
  )
}

export function getDefaultFilterOption(
  id: FiltersModuleFieldId,
  optionValue: IExpertsFilter["defaultOption"],
  query: ParsedUrlQuery,
  hasValidOptionInQuery: boolean
): IExpertsFilter["defaultOption"] {
  let defaultOption = optionValue

  try {
    if (id === FiltersModuleFieldId.PRICE) {
      if (
        !(defaultOption as IFilterOption["id"][]).length ||
        typeof defaultOption !== "object"
      ) {
        return []
      }

      const { maxPrice } = query

      defaultOption = (defaultOption as IFilterOption["id"][]).map((opt) =>
        Number(opt)
      )

      const [min, max] = defaultOption

      const currentMaxPrice =
        hasValidOptionInQuery && Number(maxPrice) <= Number(max)
          ? Number(maxPrice)
          : max

      /**
       * [
       *   min price coming from contentful,
       *   max price coming from contentful,
       *   currentMin price value based on contentful config,
       *   currentMax price value based on contentful config and query string param,
       * ]
       */
      defaultOption = [
        min,
        currentMaxPrice > max ? currentMaxPrice : max,
        min,
        currentMaxPrice < min ? min : currentMaxPrice,
      ]
    } else {
      const [, opt] = defaultOption.toString().split(DELIMITER)

      // @ts-expect-error
      defaultOption = hasValidOptionInQuery ? query[id] : opt
    }
  } catch (error) {
    console.error(error)
  }

  return defaultOption
}

export function isFullMode(mode: FiltersModuleMode): boolean {
  return mode === FiltersModuleMode.FULL
}

export function isLimitedMode(mode: FiltersModuleMode): boolean {
  return mode === FiltersModuleMode.LIMITED
}

export const findOptionNameById = (
  options: IFilterOption[],
  defaultOption: UseTypeOf<IExpertsFilter, "defaultOption">
): string | undefined => {
  let name

  for (let i = 0; i < options.length; i++) {
    const opt = options[i]

    if (opt.id === defaultOption) {
      name = opt.name
      break
    }

    const foundChild =
      opt.children &&
      (opt.children as IFilterOption[]).find(
        (child) => child.id === defaultOption
      )

    if (foundChild) {
      name = foundChild.name
      break
    }
  }

  return name
}

export function getProductTypeBtnLabel(option: IFilterOption) {
  if (option.id === "ANONYMOUS" && option.name) {
    return option.name.split("-")[0]
  }
  return option.name
}
