import { createContext } from "react"
import { IFilterValues } from "../types"
import { initFiltersState } from "../reducers/filtersReducer"
import { IFiltersReducer } from "../hooks/useFiltersReducer"

type IFiltersContext = Omit<IFiltersReducer, "sanitizedQuery"> & {
  isPageConfig: boolean
  redirect: string | undefined
  withSearching: boolean
  freeOnly: boolean
}

export default createContext<IFiltersContext>({
  state: initFiltersState,
  dispatch: () => {},
  layoutSettings: {
    isFullMode: false,
    isLimitedMode: false,
  },
  redirect: undefined,
  applyFilter: () => {},
  filtersState: {} as IFilterValues,
  setFiltersState: () => {},
  isPageConfig: false,
  withSearching: false,
  freeOnly: false,
})
