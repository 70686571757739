import React, { useContext } from "react"
import clsx from "clsx"
import { Box, Dialog, DialogContent, Hidden } from "../../_common"
import { FiltersListFooter } from "./components/FiltersList/FiltersListFooter"
import { FiltersListHeader } from "./components/FiltersList/FiltersListHeader"
import { FiltersListBody } from "./components/FiltersList/FiltersListBody"
import { TopFilters } from "./components/FiltersList/TopFilters"
import FiltersContext from "./context/FiltersContext"
import { FiltersActions } from "./reducers/filtersReducer"
import { CommonFilter } from "./components/CommonFilter"
import { SearchPanel } from "./components/SearchPanel"
import { FiltersModuleFieldId } from "../../../types"
import classes from "./FiltersList.module.scss"

const FILTERBOX_DATA_TESTID = "filters-mobule-filterbox"

const FiltersList: React.FC = () => {
  const {
    dispatch,
    state,
    layoutSettings: { isFullMode, isLimitedMode },
    redirect,
    isPageConfig,
    withSearching,
  } = useContext(FiltersContext)

  const rootClasses = clsx({
    [classes.rootFull]: isFullMode,
    [classes.rootLimited]: isLimitedMode,
    [classes.pageConfig]: isPageConfig,
    [classes.rootRounded]: isPageConfig,
  })

  const headerClasses = clsx({
    [classes.headerFull]: isFullMode,
  })

  const footerClasses = clsx({
    [classes.footerFull]: isFullMode,
  })

  const bodyClasses = clsx({
    [classes.bodyLimited]: isLimitedMode,
    [classes.bodyFull]: isFullMode,
  })

  const handleFiltersModalClose = () => {
    dispatch({
      type: FiltersActions.SET_FILTERS_MODAL_OPEN,
      payload: {
        filtersModalOpen: false,
      },
    })
  }

  return isFullMode ? (
    <>
      <Hidden mdUp>
        {withSearching && <SearchPanel />}
        <TopFilters />
        <Dialog
          fullScreen
          open={state.filtersModalOpen}
          scroll="paper"
          onClose={handleFiltersModalClose}
          className={rootClasses}
          data-testid={FILTERBOX_DATA_TESTID}
        >
          <FiltersListHeader
            className={headerClasses}
            onClose={handleFiltersModalClose}
          />
          <DialogContent className={bodyClasses}>
            <CommonFilter id={FiltersModuleFieldId.SORTING} />
            <FiltersListBody />
          </DialogContent>
          <FiltersListFooter redirect={redirect} className={footerClasses} />
        </Dialog>
      </Hidden>

      <Hidden mdDown>
        <Box className={rootClasses} data-testid={FILTERBOX_DATA_TESTID}>
          <FiltersListHeader className={headerClasses} />
          <Box className={bodyClasses}>
            <FiltersListBody />
          </Box>
          <FiltersListFooter redirect={redirect} className={footerClasses} />
        </Box>
      </Hidden>
    </>
  ) : (
    <Box className={rootClasses} data-testid={FILTERBOX_DATA_TESTID}>
      <Box className={bodyClasses}>
        <FiltersListBody />
      </Box>
      <FiltersListFooter redirect={redirect} className={footerClasses} />
    </Box>
  )
}

export default FiltersList
