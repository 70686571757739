import React from "react"
import Script from "next/script"

interface IFullDomProps {
  html: string
  id: string
}

const LiteYTPlayer: React.FC<IFullDomProps> = ({ html, id }) => {
  return (
    <>
      <Script
        strategy="lazyOnload"
        type="module"
        src="/lite-youtube/lite-youtube.js"
      />

      <div id={id} dangerouslySetInnerHTML={{ __html: html }} />
    </>
  )
}

export default LiteYTPlayer
