import React from "react"

/**
 * @todo move StatusIdicator to common
 */
import StatusIndicator from "../Experts/StatusIndicator"
import {
  ListItemText,
  ListItemAvatar,
  Avatar,
  MenuItem,
  Box,
} from "../_common/MaterialUI"
import { ISuggestion } from "./types"
import classes from "./Suggestion.module.scss"

interface ISuggestionProps extends ISuggestion {
  isHighlighted?: boolean
  rootProps: React.HTMLAttributes<HTMLLIElement>
}

export const Suggestion: React.FC<ISuggestionProps> = (suggestion) => {
  const variant = "square"

  return (
    <MenuItem
      {...suggestion.rootProps}
      selected={suggestion.isHighlighted}
      component="li"
      className={classes.item}
    >
      <ListItemAvatar classes={{ root: classes.listItemAvatarRoot }}>
        <Box position="relative">
          <Avatar
            src={suggestion.avatar}
            alt={suggestion.name}
            classes={{ root: classes.avatarRoot }}
            variant={variant}
          />
          {suggestion.status && (
            <StatusIndicator
              onlineStatus={suggestion.status}
              className={classes.statusIndicator}
            />
          )}
        </Box>
      </ListItemAvatar>
      <ListItemText classes={{ primary: classes.listItemText }}>
        {suggestion.name}
      </ListItemText>
    </MenuItem>
  )
}
