"use client"

import React, { useEffect } from "react"
import FullDOMBase from "./FullDOMBase"

interface IFullDomProps {
  html: string
  css: string
  sys: { id: string }
}

const FullDOM: React.FC<IFullDomProps> = ({ html, css, sys }) => {
  useEffect(() => {
    if (!document) {
      return
    }

    const newStyle = document.createElement("style")
    newStyle.type = "text/css"
    newStyle.appendChild(document.createTextNode(css))

    document.getElementsByTagName("head")[0].appendChild(newStyle)
  }, [css])

  return <FullDOMBase html={html} sys={sys} />
}

export default FullDOM
