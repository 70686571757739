import React from "react"
import IconBase from "./IconBase"
import { IIconProps } from "./types"
import { useCheckBrand } from "./utils/useCheckBrand"

const VisibilityIcon: React.FC<IIconProps> = ({
  color = "currentColor",
  className,
}) => {
  const { isQuesticoBrand } = useCheckBrand()
  return (
    <IconBase className={className}>
      {isQuesticoBrand ? (
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.25 10.215L3.63793 9.78154L3.63622 9.78398L4.25 10.215ZM3 11.995L2.38622 11.564L2.08354 11.995L2.38622 12.426L3 11.995ZM4.25 13.775L3.63622 14.206L3.63845 14.2092L4.25 13.775ZM19.74 13.775L20.3521 14.2085L20.3538 14.206L19.74 13.775ZM20.99 11.995L21.6038 12.426L21.9065 11.995L21.6038 11.564L20.99 11.995ZM19.74 10.215L20.3538 9.78397L20.3515 9.78082L19.74 10.215ZM3.63622 9.78398L2.38622 11.564L3.61378 12.426L4.86378 10.646L3.63622 9.78398ZM2.38622 12.426L3.63622 14.206L4.86378 13.344L3.61378 11.564L2.38622 12.426ZM3.63845 14.2092C7.92704 20.2497 16.0728 20.251 20.3521 14.2085L19.1279 13.3415C15.4472 18.539 8.55296 18.5403 4.86155 13.3408L3.63845 14.2092ZM20.3538 14.206L21.6038 12.426L20.3762 11.564L19.1262 13.344L20.3538 14.206ZM21.6038 11.564L20.3538 9.78398L19.1262 10.646L20.3762 12.426L21.6038 11.564ZM20.3515 9.78082C16.063 3.74025 7.91721 3.73896 3.63794 9.78155L4.86206 10.6485C8.54279 5.45105 15.437 5.44975 19.1285 10.6492L20.3515 9.78082Z"
            fill={color}
          />
          <path
            d="M12 14.9951C13.6569 14.9951 15 13.652 15 11.9951C15 10.3383 13.6569 8.99512 12 8.99512C10.3431 8.99512 9 10.3383 9 11.9951C9 13.652 10.3431 14.9951 12 14.9951Z"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ) : (
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.25 10.215L3 11.995L4.25 13.775C8.24 19.395 15.76 19.395 19.74 13.775L20.99 11.995L19.74 10.215C15.75 4.595 8.23 4.595 4.25 10.215Z"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12 14.9951C13.6569 14.9951 15 13.652 15 11.9951C15 10.3383 13.6569 8.99512 12 8.99512C10.3431 8.99512 9 10.3383 9 11.9951C9 13.652 10.3431 14.9951 12 14.9951Z"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </IconBase>
  )
}

export default VisibilityIcon
