import React, { useCallback, useState } from "react"
import clsx from "clsx"

import type { AutocompleteRenderInputParams } from "@mui/material"

import { TInputValidation, IAuthInput, TInput } from "../../types"
import ErrorCircleSmallIcon from "../../../_common/QuesticoIcon/ErrorCircleSmallIcon"
import { TextField, Box } from "../../../_common"
import { inputErrorMessage } from "../../consts"
import VisibilityIndicator from "../VisibilityIcon"
import Indicator from "./Indicator"
import classes from "./Input.module.scss"

export interface InputWithIndicatorProps
  extends Pick<React.ComponentProps<typeof TextField>, "inputProps"> {
  value?: string
  name: string
  type: TInput
  label?: string
  placeholder?: string
  required?: boolean
  maxLength?: number
  id?: string
  autoComplete?: string
  error?: TInputValidation
  withVisibilityControl?: boolean
  withIndicator?: boolean
  isDisabled?: boolean
  onChange?: (event: React.ChangeEvent<IAuthInput>) => void
  dataTestId?: string
  rootClass?: string
  suggestionProps?: AutocompleteRenderInputParams
}

// @todo: move to _common folder
const InputWithIndicator: React.FC<InputWithIndicatorProps> = ({
  value,
  name,
  type,
  label,
  placeholder,
  error,
  required = false,
  maxLength,
  id = name,
  autoComplete,
  withVisibilityControl,
  withIndicator = false,
  isDisabled = false,
  onChange,
  dataTestId,
  inputProps,
  rootClass,
  suggestionProps,
}) => {
  const [inputType, setInputType] = useState(type)

  const visibilityHandler = useCallback((isVisible: boolean) => {
    setInputType(isVisible ? "text" : "password")
  }, [])
  const {
    InputLabelProps,
    InputProps,
    inputProps: suggestionInputProps,
    ...suggestionRest
  } = suggestionProps || {}
  return (
    <div className={classes.root}>
      <TextField
        value={value}
        name={name}
        label={label}
        placeholder={placeholder}
        required={required}
        type={inputType}
        id={id}
        autoComplete={autoComplete || "on"}
        onChange={onChange}
        variant="outlined"
        inputProps={{
          ...inputProps,
          ...suggestionInputProps,
          maxLength,
          "data-testid": dataTestId,
        }}
        InputProps={{
          ...InputProps,
          classes: {
            root: clsx(classes.rootInput, rootClass),
            input: classes.input,
            notchedOutline: classes.notchedOutline,
          },
        }}
        InputLabelProps={{
          ...InputLabelProps,
          classes: {
            root: classes.label,
          },
        }}
        className={classes.textField}
        error={!!error}
        disabled={isDisabled}
        {...suggestionRest}
      />
      {withVisibilityControl && (
        <VisibilityIndicator visibilityHandler={visibilityHandler} />
      )}
      {withIndicator && (
        <div className={classes.indicatorWrapper}>
          <Indicator value={value} />
        </div>
      )}
      {error && (
        <div className={classes.errorHint}>
          <Box component="span" className={classes.errorHintIcon}>
            <ErrorCircleSmallIcon width={13} height={13} />
          </Box>
          <span data-testid={`${id}-error`}>{inputErrorMessage[error]}</span>
        </div>
      )}
    </div>
  )
}

export default InputWithIndicator
