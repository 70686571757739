import React from "react"
import { IReview } from "../../../types"

import useFetchDataByPage from "../../../hooks/api/useFetchDataByPage"
import { SWR_KEYS } from "../../../utils/rest"

import Reviews from "./Reviews"

interface IReviewsProps {
  advisorId: number
}

const ReviewsConnected: React.FC<IReviewsProps> = ({ advisorId }) => {
  const { data: reviewsData, loadMore, isLoading } = useFetchDataByPage<
    IReview,
    { advisor_id: number; size: number }
  >({
    url: SWR_KEYS.ADVISOR_REVIEWS,
    operationName: "advisor-reviews",
    payload: {
      size: 10,
      advisor_id: advisorId,
    },
  })

  const { content, isLast } = reviewsData

  return (
    <Reviews
      isLast={isLast}
      reviews={content}
      loadMore={loadMore}
      isLoading={isLoading}
    />
  )
}

export default ReviewsConnected
