"use client"

import React, { useContext } from "react"
import clsx from "clsx"

import { Box, Hidden, Typography, Portal as MUIPortal } from "../_common"
import { IOrderByType } from "./Filters/types"
import Experts from "./Experts.connected"
import FiltersList from "./Filters/FiltersList"
import { getCategoryNos } from "./Filters/utils"
import { useFiltersSelector } from "./Filters/selectors/filtersSelectors"
import { CommonFilter } from "./Filters/components/CommonFilter"
import { SearchPanel } from "./Filters/components/SearchPanel"
import { ExpertsNotFound } from "./StaticList/ExpertsNotFound"
import RichText from "../RichText"
import FiltersContext from "./Filters/context/FiltersContext"
import {
  FiltersModuleDisplay,
  FiltersModuleFieldId,
  IAdvisor,
} from "../../types"
import TwoColumnLayout from "../Layout"
import { useDomRefs } from "../../hooks/domRefs"
import classes from "./FiltersModule.module.scss"

interface IFiltersModuleProps {
  size: number
  includeListingNos?: string
  display: FiltersModuleDisplay[]
  emptyState?: {
    json: any
  }
  withPagination: boolean
  expertListHeadline?: string
  sys: { id: string }
  listingName?: string
  renderExpertItem?: (expert: IAdvisor) => JSX.Element
}

const FiltersModule: React.FC<IFiltersModuleProps> = ({
  size,
  includeListingNos,
  display,
  emptyState,
  withPagination,
  expertListHeadline,
  sys,
  listingName,
  renderExpertItem,
}) => {
  const {
    state,
    layoutSettings,
    isPageConfig,
    withSearching,
    filtersState,
  } = useContext(FiltersContext)

  const ids = includeListingNos
    ? includeListingNos.split(",").map((id) => Number(id.trim()))
    : []

  const { sorting } = state
  const { enabledIds, sanitizedSearch } = useFiltersSelector(state)
  const { isFullMode, isLimitedMode } = layoutSettings

  const { refsMap, withFiltersPortal } = useDomRefs()

  const showList = display?.includes(FiltersModuleDisplay.LIST)

  const rootClasses = clsx({
    [classes.pageConfig]: isPageConfig,
  })

  const filterListWrapperClasses = clsx({
    [classes.filterListWrapperLimited]: isLimitedMode,
    [classes.filterListWrapperFull]: isFullMode,
    [classes.filterListWrapperInPortal]: withFiltersPortal.current,
    [classes.filterListWrapperWithList]: showList,
  })

  const filtersContent = (
    <Box className={filterListWrapperClasses}>
      {isLimitedMode && expertListHeadline && (
        <Typography
          variant="h4"
          component="h2"
          align="center"
          className={classes.expertListHeadline}
          data-testid="filters-module-headline-limited-mode"
        >
          {expertListHeadline}
        </Typography>
      )}
      <FiltersList />
    </Box>
  )

  const showFilters =
    enabledIds.length > 0 && display?.includes(FiltersModuleDisplay.FILTERS)

  const filtersNode = withFiltersPortal.current ? null : filtersContent

  const listNode = (
    <>
      <Box className={classes.rootList}>
        {isFullMode && (
          <Hidden mdDown>
            <Box className={classes.listActions}>
              {withSearching && <SearchPanel />}
              {sorting?.enabled && (
                <Box className={classes.sortingFilter}>
                  <CommonFilter id={FiltersModuleFieldId.SORTING} />
                </Box>
              )}
            </Box>
          </Hidden>
        )}

        <Experts
          sys={sys}
          freeOnly={filtersState.freeOnly}
          listingName={listingName as string}
          minPrice={filtersState.price?.[2]}
          maxPrice={filtersState.price?.[3]}
          headline={expertListHeadline as string}
          includeListingNos={ids}
          size={size}
          withPagination={withPagination || false}
          searchTerm={sanitizedSearch}
          productType={filtersState.productType}
          categoryNos={getCategoryNos(filtersState.method, filtersState.topic)}
          gender={filtersState.gender}
          language={filtersState.language}
          tag={filtersState.tag}
          orderBy={sorting?.defaultOption as IOrderByType}
          emptyState={
            <ExpertsNotFound
              emptyState={emptyState ? <RichText content={emptyState} /> : null}
            />
          }
          renderExpertItem={renderExpertItem}
        />
      </Box>
      {withFiltersPortal.current && showFilters && (
        <Hidden mdDown>
          <MUIPortal container={refsMap.filtersPortal?.current}>
            {filtersContent}
          </MUIPortal>
        </Hidden>
      )}
    </>
  )

  return (
    <Box className={rootClasses}>
      {isLimitedMode && filtersNode}
      <TwoColumnLayout
        sideColumn={showFilters && isFullMode && filtersNode}
        mainColumn={showList && listNode}
        sideSizes={{
          xs: 12,
          md: 3,
        }}
        mainSizes={{
          xs: 12,
          md: 9,
        }}
      />
    </Box>
  )
}

export default FiltersModule
