import React, { useContext, useEffect, useMemo } from "react"

import { ExpansionPanel } from "../../../_common"
import FiltersContext from "../context/FiltersContext"
import { FiltersActions } from "../reducers/filtersReducer"
import { IFilterProps } from "../types"
import { RadioFilter } from "./RadioFilter"
import classes from "./ExpandedRadioFilter.module.scss"

export const ExpandedRadioFilter: React.FC<IFilterProps> = ({
  filter,
  handleFilterValueChange,
}) => {
  const { dispatch } = useContext(FiltersContext)

  const { options, title, defaultOptionTitle, expanded } = filter

  const availableOptions = useMemo(
    () => options.filter((option) => option.enabled),
    [options]
  )

  useEffect(() => {
    return () => {
      if (expanded) {
        dispatch({
          type: FiltersActions.SET_FILTER_EXPANDED,
          payload: {
            id: filter.id,
            expanded: false,
          },
        })
      }
    }
  }, [dispatch, expanded, filter.id])

  if (!availableOptions.length) return null

  return (
    <ExpansionPanel
      defaultExpanded={expanded}
      panelClassName={classes.panelClassName}
      detailsClassName={classes.detailsClassName}
      headingClassName={classes.headingClassName}
      summaryClassName={classes.summaryClassName}
      detailsWrapperClassName={classes.detailsWrapperClassName}
      items={[
        {
          title: defaultOptionTitle,
          subTitle: title,
          details: (
            <RadioFilter
              filter={filter}
              handleFilterValueChange={handleFilterValueChange}
              className={classes.radioGroup}
            />
          ),
        },
      ]}
    />
  )
}
