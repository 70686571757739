import clsx from "clsx"
import React, { useContext, useMemo } from "react"
import { IFilterOption } from "../../../../types"

import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Hidden,
} from "../../../_common"
import FiltersContext from "../context/FiltersContext"
import { IFilterProps } from "../types"
import classes from "./RadioFilter.module.scss"

export const RadioFilter: React.FC<IFilterProps> = ({
  filter,
  handleFilterValueChange,
  className,
}) => {
  const {
    layoutSettings: { isFullMode, isLimitedMode },
  } = useContext(FiltersContext)

  const { id, options, title, defaultOption } = filter

  const availableOptions = useMemo(
    () => options.filter((option) => option.enabled),
    [options]
  )

  const formLabelClasses = clsx({
    [classes.labelLimited]: isLimitedMode,
    [classes.labelFull]: isFullMode,
  })

  const radioButtonClasses = clsx(classes.radio, {
    [classes.radioTypeExpanded]: isFullMode,
  })

  const radioLabelClasses = clsx(classes.radioLabel, {
    [classes.radioLabelTypeExpanded]: isFullMode,
  })

  const radioButtonCheckedClasses = clsx({
    [classes.radioButtonChecked]: isFullMode,
  })

  if (!availableOptions.length) return null

  const shouldShowChildren = (option: IFilterOption) =>
    option.children &&
    (defaultOption === option.id ||
      (option.children as IFilterOption[]).some(
        (child) => child.id === defaultOption
      ))

  return (
    <FormControl key={id} fullWidth data-testid={`${id}-filter`}>
      <Hidden mdDown>
        <FormLabel className={formLabelClasses}>{title}</FormLabel>
      </Hidden>
      <RadioGroup
        value={defaultOption}
        name={`filter-${id}`}
        onChange={handleFilterValueChange}
        className={className}
      >
        {availableOptions.map((option) => (
          <React.Fragment key={option.id}>
            <FormControlLabel
              classes={{
                label: radioLabelClasses,
              }}
              value={option.id.toString()}
              control={
                <Radio
                  color="primary"
                  classes={{ checked: radioButtonCheckedClasses }}
                  inputProps={{
                    // @ts-ignore
                    "data-testid": `${id}-filter-${option.id}-option`,
                  }}
                />
              }
              label={option.name}
              className={radioButtonClasses}
            />

            {shouldShowChildren(option) &&
              (option.children as IFilterOption[]).map((child) => (
                <FormControlLabel
                  style={{ paddingLeft: 10 }}
                  classes={{
                    label: radioLabelClasses,
                  }}
                  key={child.id}
                  value={child.id.toString()}
                  control={
                    <Radio
                      color="primary"
                      classes={{ checked: radioButtonCheckedClasses }}
                      inputProps={{
                        // @ts-ignore
                        "data-testid": `${id}-filter-${child.id}-option`,
                      }}
                    />
                  }
                  label={child.name}
                  className={radioButtonClasses}
                />
              ))}
          </React.Fragment>
        ))}
      </RadioGroup>
    </FormControl>
  )
}
