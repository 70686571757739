import clsx from "clsx"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { toPriceString } from "../../../../utils/utils"

import {
  FormControl,
  FormLabel,
  Slider,
  Box,
  FormControlLabel,
  Checkbox,
} from "../../../_common"
import { PRICE_CHANGE_STEP } from "../consts"
import FiltersContext from "../context/FiltersContext"
import { FiltersActions } from "../reducers/filtersReducer"
import { IFilterProps } from "../types"
import classes from "./SliderFilter.module.scss"

export const SliderFilter: React.FC<IFilterProps> = ({ filter }) => {
  const {
    state: { isFreeChecked },
    dispatch,
    layoutSettings: { isFullMode, isLimitedMode },
    freeOnly,
  } = useContext(FiltersContext)

  const { id, defaultOption, title } = filter

  const [min, max, currentMin, currentMax] = defaultOption as number[]

  const [currentSliderValue, setCurrentSliderValue] = useState(currentMax)

  useEffect(() => {
    setCurrentSliderValue(currentMax)
  }, [currentMax])

  const formLabelClasses = clsx({
    [classes.labelLimited]: isLimitedMode,
    [classes.labelFull]: isFullMode,
  })

  const wrapperClasses = clsx({
    [classes.wrapperLimited]: isLimitedMode,
    [classes.wrapperFull]: isFullMode,
  })

  const markLabelClasses = clsx(classes.markLabel, {
    [classes.markLabelFull]: isFullMode,
  })

  const gratisCheckboxClasses = clsx({
    [classes.gratisCheckbox]: isFullMode,
  })

  const handleFreeCheckboxUpdate = useCallback(() => {
    dispatch({
      type: FiltersActions.CHANGE_FREE_ONLY,
      payload: {},
    })
  }, [dispatch])

  return (
    <FormControl
      className={wrapperClasses}
      fullWidth
      data-testid={`${id}-filter`}
    >
      <Box className={classes.sliderTitleWrapper}>
        <FormLabel className={formLabelClasses}>{title}</FormLabel>
        {freeOnly && (
          <FormControlLabel
            name="postpayment-opt-in"
            onChange={handleFreeCheckboxUpdate}
            control={
              <Checkbox
                color="primary"
                checked={isFreeChecked}
                inputProps={{
                  // @ts-ignore
                  "data-testid": "price-filter-gratis-checkbox",
                }}
              />
            }
            label="Gratis"
            labelPlacement="end"
            className={gratisCheckboxClasses}
          />
        )}
      </Box>
      <Box className={classes.sliderWrapper}>
        <Slider
          size="small"
          classes={{
            markLabel: markLabelClasses,
            mark: classes.mark,
          }}
          disabled={isFreeChecked}
          data-testid={`${id}-filter-base`}
          className={classes.filter}
          valueLabelDisplay="off"
          /* components={{ Thumb: "div" }} */
          aria-label="Always visible"
          value={currentSliderValue}
          step={PRICE_CHANGE_STEP}
          min={min}
          max={max}
          marks={[
            {
              value: min,
              label: toPriceString(min),
            },
            {
              value: max,
              label:
                currentSliderValue === max
                  ? toPriceString(max)
                  : toPriceString(currentSliderValue),
            },
          ]}
          onChange={(_e, value) => {
            const activeOption = value as number

            setCurrentSliderValue(activeOption)
          }}
          onChangeCommitted={(_e, value) => {
            const newMax = value as number

            dispatch({
              type: FiltersActions.UPDATE_FILTER_ACTIVE_OPTION,
              payload: {
                id,
                activeOption: [min, max, currentMin, newMax],
                isFreeChecked: newMax === 0,
              },
            })
          }}
        />
      </Box>
    </FormControl>
  )
}
