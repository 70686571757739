import { useMemo } from "react"
import {
  FiltersModuleFieldId,
  IExpertsFilter,
  IFilterOption,
  UseTypeOf,
} from "../../../../types"
import { toPriceString } from "../../../../utils/utils"
import { IFiltersState, IFilterValues } from "../types"
import { findOptionNameById } from "../utils"

interface IFiltersSelector {
  enabledIds: FiltersModuleFieldId[]
  filterOptionsForQuery: {
    [key in FiltersModuleFieldId]: IFilterOption["id"] | boolean
  }
  activeOptions: IFilterValues
  initOptions: IFilterValues
  activeFiltersTitles: {
    id: FiltersModuleFieldId
    title: string
    isActive: boolean
  }[]
  activeOptionsForTracking: {
    type: FiltersModuleFieldId
    value: IExpertsFilter["defaultOption"] | boolean | undefined
  }[]
  sanitizedSearch: string
  searchWords: string[]
}

export const useFiltersSelector = (state: IFiltersState): IFiltersSelector => {
  const { filters, ids, search, isFreeChecked } = state
  const sanitizedSearch = search.replace(/ +(?= )/g, "").trim()

  const searchWords = sanitizedSearch ? sanitizedSearch.split(" ") : []

  const enabledIds = useMemo(() => ids.filter((id) => filters[id]?.enabled), [
    filters,
    ids,
  ])

  const activeOptions = useMemo(() => {
    const options = ids.reduce(
      (acc, id) => ({
        ...acc,
        [id]: filters[id]?.defaultOption,
      }),
      {} as IFilterValues
    )

    return {
      ...options,
      [FiltersModuleFieldId.FREE_ONLY]: isFreeChecked,
    }
  }, [filters, ids, isFreeChecked])

  const activeOptionsForTracking = useMemo(() => {
    const options = ids.map((filterKey) => {
      return {
        type: filterKey,
        value:
          filterKey === FiltersModuleFieldId.PRICE
            ? (filters[filterKey]?.defaultOption as IFilterOption["id"][])[3]
            : filters[filterKey]?.defaultOption,
      }
    })

    return [
      ...options,
      {
        type: FiltersModuleFieldId.FREE_ONLY,
        value: isFreeChecked,
      },
    ]
  }, [filters, ids, isFreeChecked])

  const initOptions = useMemo(() => {
    const options = ids.reduce(
      (acc, id) => ({
        ...acc,
        [id]: filters[id]?.initOption,
      }),
      {} as IFilterValues
    )

    return { ...options, [FiltersModuleFieldId.FREE_ONLY]: false }
  }, [filters, ids])

  const filterOptionsForQuery = useMemo(() => {
    const options = ids
      // We do not add to the query not enabled filters or the filters, which have value "ALL"
      // Why? we do not want unintentional controlling of the filters via URL parameters.
      // If the value is not the default value, then the content editor wants to use this filter.
      .filter(
        (id) => filters[id]?.enabled || filters[id]?.defaultOption !== "ALL"
      )
      .reduce((acc, id) => {
        const { defaultOption } = filters[id] || {}

        // @note: overwrite query param name for price
        if (id === FiltersModuleFieldId.PRICE && defaultOption) {
          if (isFreeChecked) {
            return {
              ...acc,
              freeOnly: true,
            }
          }

          const [, , , maxPrice] = defaultOption as IFilterOption["id"][]

          return {
            ...acc,
            maxPrice,
          }
        }

        return {
          ...acc,
          [id]: defaultOption,
        }
      }, {} as UseTypeOf<IFiltersSelector, "filterOptionsForQuery">)

    return { ...options, [FiltersModuleFieldId.FREE_ONLY]: isFreeChecked }
  }, [filters, ids, isFreeChecked])

  const activeFiltersTitles = useMemo(
    () =>
      enabledIds.map((id) => {
        const { title, options, defaultOption } = filters[id]!

        if (id === FiltersModuleFieldId.PRICE) {
          const maxPrice = (defaultOption as IFilterOption["id"][])[1]
          const currentPrice = (defaultOption as IFilterOption["id"][])[3]
          const isPriceChanged = currentPrice !== maxPrice

          if (isPriceChanged) {
            return {
              id,
              title:
                currentPrice === 0
                  ? "Gratis"
                  : `≤ ${toPriceString(Number(currentPrice))}/min`,
              isActive: true,
            }
          }
        }

        const optionName = findOptionNameById(options, defaultOption)

        return {
          id,
          title: defaultOption !== "ALL" && optionName ? optionName : title,
          isActive: !!(defaultOption !== "ALL" && optionName),
        }
      }),
    [enabledIds, filters]
  )

  return {
    enabledIds,
    activeOptions,
    filterOptionsForQuery,
    activeFiltersTitles,
    activeOptionsForTracking,
    initOptions,
    sanitizedSearch,
    searchWords,
  }
}
