import React, { useCallback, useContext } from "react"
import clsx from "clsx"
import { AdviqoButton, AdviqoLink, Box } from "../../../../_common"
import FiltersContext from "../../context/FiltersContext"
import { useFiltersSelector } from "../../selectors/filtersSelectors"
import { useTracking } from "../../../../../hooks/tracking"
import { ClientTrackingEventName } from "../../../../../types"
import classes from "./FiltersListFooter.module.scss"

interface IFiltersListFooter {
  className: string
  redirect?: string
}

export const FiltersListFooter: React.FC<IFiltersListFooter> = ({
  redirect,
  className,
}) => {
  const { trackEvent: trackCustomEvent } = useTracking()

  const {
    state,
    applyFilter,
    layoutSettings: { isFullMode, isLimitedMode },
  } = useContext(FiltersContext)

  const {
    activeOptionsForTracking,
    filterOptionsForQuery,
    sanitizedSearch,
  } = useFiltersSelector(state)

  const submitClasses = clsx(classes.action, {
    [classes.actionFull]: isFullMode,
    [classes.actionLimited]: isLimitedMode,
  })

  const clickHandler = useCallback(() => {
    trackCustomEvent({
      eventName: ClientTrackingEventName.PRODUCT_SEARCH,
      properties: {
        query: sanitizedSearch,
        filters: activeOptionsForTracking,
        source: "filterbox",
        suggestions: false,
      },
    })
  }, [activeOptionsForTracking, sanitizedSearch, trackCustomEvent])

  return (
    <Box className={className}>
      {redirect ? (
        <AdviqoLink
          type="primary"
          className={submitClasses}
          onClick={clickHandler}
          data-testid="filters-redirect-link"
          to={{
            pathname: redirect,
            query: filterOptionsForQuery,
          }}
        >
          Suchen
        </AdviqoLink>
      ) : (
        <AdviqoButton
          onClick={applyFilter}
          fullWidth
          label="Filter anwenden"
          buttonClassName={submitClasses}
          testId="filters-apply-filter-button"
        />
      )}
    </Box>
  )
}
