import clsx from "clsx"
import React, { useContext } from "react"

import { Box, Hidden, LinkButton } from "../../../../_common"
import ChevronIcon from "../../../../_common/QuesticoIcon/ChevronIcon"
import FiltersContext from "../../context/FiltersContext"
import classes from "./FiltersListHeader.module.scss"

interface IFiltersListHeader {
  className: string
  onClose?: () => void
}

export const FiltersListHeader: React.FC<IFiltersListHeader> = ({
  className,
  onClose,
}) => {
  const {
    setFiltersState,
    layoutSettings: { isFullMode },
  } = useContext(FiltersContext)

  const resetButtonClasses = clsx({
    [classes.resetButtonFull]: isFullMode,
  })

  return (
    <Box className={className}>
      {isFullMode && (
        <Hidden mdUp>
          <Box
            className={classes.icon}
            onClick={onClose}
            data-testid="filters-module-close-modal"
          >
            <ChevronIcon direction="left" color="#404041" />
          </Box>
          <Box component="h2" className={classes.title}>
            Filters
          </Box>
        </Hidden>
      )}
      <LinkButton
        label="Zurücksetzen"
        onClick={() => {
          setFiltersState()
        }}
        testId="reset-all-button"
        buttonClassName={resetButtonClasses}
      />
    </Box>
  )
}
