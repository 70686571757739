import { useFlag, useVariant, useFlagsStatus } from "@unleash/nextjs/client"
import { FeatureName } from "../unleash-types"

export const useUnleash = (defenition: FeatureName) => {
  const flagsStatus = useFlagsStatus()
  const isEnabled = useFlag(defenition)
  const variant = useVariant(defenition)

  return {
    flagsStatus,
    isEnabled,
    variant,
  }
}
