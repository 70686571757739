import { FiltersModuleFieldId, IExpertsFilter } from "../../../../types"
import { IFiltersState } from "../types"

export enum FiltersActions {
  UPDATE_FILTER_ACTIVE_OPTION = "UPDATE_FILTER_ACTIVE_OPTION",
  SET_FILTER_EXPANDED = "SET_FILTER_EXPANDED",
  SET_FILTERS_MODAL_OPEN = "SET_FILTERS_MODAL_OPEN",
  RESET_FILTERS = "RESET_FILTERS",

  UPDATE_SORTING_ACTIVE_OPTION = "UPDATE_SORTING_ACTIVE_OPTION",

  UPDATE_SEARCH = "UPDATE_SEARCH",

  CHANGE_FREE_ONLY = "CHANGE_FREE_ONLY",
}

export interface IFiltersActionsPayload {
  activeOption?: IExpertsFilter["defaultOption"]
  id?: FiltersModuleFieldId
  expanded?: boolean
  filtersModalOpen?: boolean
  search?: string
  isFreeChecked?: boolean
}

export interface IFiltersActions {
  type: FiltersActions
  payload: IFiltersActionsPayload
}

export const initFiltersState: IFiltersState = {
  filters: {},
  ids: [],
  filtersModalOpen: false,
  search: "",
  isFreeChecked: false,
}

export function filtersReducer(
  state: IFiltersState,
  action: IFiltersActions
): IFiltersState {
  switch (action.type) {
    case FiltersActions.UPDATE_FILTER_ACTIVE_OPTION: {
      const { id, activeOption, isFreeChecked } = action.payload

      const { filters } = state

      if (id && activeOption) {
        return {
          ...state,
          filters: {
            ...filters,
            [id]: {
              ...filters[id],
              defaultOption: activeOption,
            },
          },
          ...(isFreeChecked !== undefined && { isFreeChecked }),
        }
      }

      return state
    }

    case FiltersActions.SET_FILTER_EXPANDED: {
      const { id, activeOption, expanded, filtersModalOpen } = action.payload

      const { filters } = state

      if (id && expanded !== undefined) {
        return {
          ...state,
          ...(filtersModalOpen !== undefined && { filtersModalOpen }),
          filters: {
            ...filters,
            [id]: {
              ...filters[id],
              expanded,
              ...(!!activeOption && { defaultOption: activeOption }),
            },
          },
        }
      }

      return state
    }

    case FiltersActions.SET_FILTERS_MODAL_OPEN: {
      const { filtersModalOpen } = action.payload

      if (filtersModalOpen !== undefined) {
        return {
          ...state,
          filtersModalOpen,
        }
      }

      return state
    }

    case FiltersActions.RESET_FILTERS: {
      const { ids, filters, sorting } = state

      const { search } = action.payload

      return {
        ...state,
        filtersModalOpen: false,
        ...(search !== undefined && { search }),
        ...(sorting && {
          sorting: {
            ...sorting,
            defaultOption: sorting.initOption || sorting.options[0].id,
          },
        }),
        filters: ids.reduce((acc, curr) => {
          return {
            ...acc,
            [curr]: {
              ...filters[curr],
              defaultOption: filters[curr]?.initOption,
            },
          }
        }, {}),
        isFreeChecked: false,
      }
    }

    case FiltersActions.UPDATE_SORTING_ACTIVE_OPTION: {
      const { activeOption } = action.payload

      const { sorting } = state

      if (activeOption && sorting) {
        return {
          ...state,
          sorting: {
            ...sorting,
            defaultOption: activeOption,
          },
        }
      }

      return state
    }

    case FiltersActions.UPDATE_SEARCH: {
      const { search } = action.payload

      return {
        ...state,
        search: search || "",
      }
    }

    case FiltersActions.CHANGE_FREE_ONLY: {
      const { isFreeChecked } = state

      const newValue = !isFreeChecked

      return {
        ...state,
        isFreeChecked: newValue,
      }
    }

    default:
      console.error("Wrong action type")
      return state
  }
}
