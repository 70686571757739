import React, { useContext } from "react"
import clsx from "clsx"
import { Box } from "../../../../_common"
import FiltersContext from "../../context/FiltersContext"
import { useFiltersSelector } from "../../selectors/filtersSelectors"
import { CommonFilter } from "../CommonFilter"
import classes from "./FiltersListBody.module.scss"

export const FiltersListBody: React.FC = () => {
  const {
    state,
    layoutSettings: { isLimitedMode, isFullMode },
    isPageConfig,
  } = useContext(FiltersContext)

  const { enabledIds } = useFiltersSelector(state)

  const filterClasses = clsx({
    [classes.filterLimited]: isLimitedMode,
    [classes.filterFull]: isFullMode,
    [classes.pageConfig]: isPageConfig,
  })

  return (
    <>
      {enabledIds.map((id) => (
        <Box key={id} className={filterClasses}>
          <CommonFilter id={id} />
        </Box>
      ))}
    </>
  )
}
