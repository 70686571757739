import React from "react"
import IconBase from "./IconBase"
import { IIconProps } from "./types"
import { useCheckBrand } from "./utils/useCheckBrand"

const VisibilityOffIcon: React.FC<IIconProps> = ({
  color = "currentColor",
  className,
}) => {
  const { isQuesticoBrand } = useCheckBrand()
  return (
    <IconBase className={className}>
      {isQuesticoBrand ? (
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19.25 19.18L4.95996 5"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="square"
            strokeLinejoin="round"
          />
          <path
            d="M19.7499 13.9901L20.9999 12.2101L19.7499 10.4301C17.7199 7.57006 14.7799 6.17006 11.8599 6.21006"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="square"
          />
          <path
            d="M7.18277 8.51244C7.52107 8.27343 7.60156 7.80543 7.36254 7.46713C7.12353 7.12883 6.65552 7.04835 6.31723 7.28736L7.18277 8.51244ZM4.25 10.4199L3.63809 9.98622L3.63622 9.98888L4.25 10.4199ZM3 12.1999L2.38622 11.7689L2.08354 12.1999L2.38622 12.6309L3 12.1999ZM4.25 13.9799L3.63622 14.4109L3.63804 14.4135L4.25 13.9799ZM16.5513 17.8025C16.9173 17.6085 17.0567 17.1546 16.8626 16.7886C16.6686 16.4226 16.2147 16.2833 15.8487 16.4773L16.5513 17.8025ZM6.31723 7.28736C5.32598 7.9877 4.41593 8.88875 3.6381 9.98623L4.8619 10.8536C5.54407 9.89107 6.33402 9.11211 7.18277 8.51244L6.31723 7.28736ZM3.63622 9.98888L2.38622 11.7689L3.61378 12.6309L4.86378 10.8509L3.63622 9.98888ZM2.38622 12.6309L3.63622 14.4109L4.86378 13.5489L3.61378 11.7689L2.38622 12.6309ZM3.63804 14.4135C6.83799 18.9298 12.2263 20.0955 16.5513 17.8025L15.8487 16.4773C12.2137 18.4043 7.64201 17.47 4.86196 13.5463L3.63804 14.4135Z"
            fill={color}
          />
        </svg>
      ) : (
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19.25 19.18L4.95996 5"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M19.7499 13.9901L20.9999 12.2101L19.7499 10.4301C17.7199 7.57006 14.7799 6.17006 11.8599 6.21006"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6.75 7.8999C5.83 8.5499 4.98 9.38991 4.25 10.4199L3 12.1999L4.25 13.9799C7.24 18.1999 12.22 19.2499 16.2 17.1399"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </IconBase>
  )
}

export default VisibilityOffIcon
