"use client"

import { useSearchParams } from "next/navigation"
import React, { useCallback, useContext, useEffect } from "react"

import { Box, Chip, CloseIcon } from "../../../_common"
import FiltersContext from "../context/FiltersContext"
import { useFiltersSelector } from "../selectors/filtersSelectors"
import SearchWithSuggestions from "./SearchWithSuggestions"
import { ClientTrackingEventName } from "../../../../types"
import { useTracking } from "../../../../hooks/tracking"
import classes from "./SearchPanel.module.scss"

export const SearchPanel: React.FC = () => {
  const { state, setFiltersState } = useContext(FiltersContext)

  const { trackEvent: trackCustomEvent } = useTracking()

  const { searchWords } = useFiltersSelector(state)

  const searchParams = useSearchParams()
  const searchQueryValue = searchParams.get("search")

  const trackSearch = useCallback(
    (term: string) =>
      trackCustomEvent({
        eventName: ClientTrackingEventName.PRODUCT_SEARCH,
        properties: {
          query: term,
          source: "filter",
          suggestions: false,
        },
      }),
    [trackCustomEvent]
  )
  const handleFormSubmit = useCallback(
    (term: string, trackEvents = true) => {
      if (trackEvents) {
        trackSearch(term)
      }
      setFiltersState({
        search: term,
      })
    },
    [setFiltersState, trackSearch]
  )

  const handleChipDelete = useCallback(
    (wordIdx: number) => {
      const newSearch = searchWords
        .filter((_, idx) => wordIdx !== idx)
        .join(" ")
      setFiltersState({
        search: newSearch,
      })
      trackSearch(newSearch)
    },
    [searchWords, setFiltersState]
  )

  useEffect(() => {
    if (searchQueryValue) handleFormSubmit(searchQueryValue, false)
  }, [searchQueryValue])

  return (
    <Box className={classes.root} data-testid="filters-search-panel">
      <Box className={classes.inputWrapper}>
        <SearchWithSuggestions onSubmit={handleFormSubmit} />
      </Box>
      {searchWords.length > 0 && (
        <Box component="ul" className={classes.chips}>
          {searchWords.map((word, idx) => (
            <Box component="li" key={idx} className={classes.chipWrapper}>
              <Chip
                label={word}
                className={classes.chip}
                onDelete={() => handleChipDelete(idx)}
                variant="outlined"
                color="primary"
                deleteIcon={
                  <CloseIcon data-testid="search-panel-chip-close-button" />
                }
                classes={{
                  label: classes.chipLabel,
                  deleteIconOutlinedColorPrimary:
                    classes.deleteIconOutlinedColorPrimary,
                }}
              />
            </Box>
          ))}
        </Box>
      )}
    </Box>
  )
}
