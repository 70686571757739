import clsx from "clsx"
import React, { useCallback, useContext } from "react"

import { FiltersModuleFieldId } from "../../../../../types"
import { Box, Chip, LinkButton } from "../../../../_common"
import FilterIcon from "../../../../_common/QuesticoIcon/FilterIcon"
import FiltersContext from "../../context/FiltersContext"
import { FiltersActions } from "../../reducers/filtersReducer"
import { useFiltersSelector } from "../../selectors/filtersSelectors"
import classes from "./TopFilters.module.scss"

export const TopFilters: React.FC = () => {
  const { state, dispatch } = useContext(FiltersContext)

  const { activeFiltersTitles } = useFiltersSelector(state)

  const handleFiltersModalOpen = useCallback(() => {
    dispatch({
      type: FiltersActions.SET_FILTERS_MODAL_OPEN,
      payload: {
        filtersModalOpen: true,
      },
    })
  }, [dispatch])

  const handleChipClick = useCallback(
    (id: FiltersModuleFieldId) => {
      dispatch({
        type: FiltersActions.SET_FILTER_EXPANDED,
        payload: {
          id,
          expanded: true,
          filtersModalOpen: true,
        },
      })
    },
    [dispatch]
  )

  return (
    <Box className={classes.root} data-testid="top-filters">
      <Box className={classes.row}>
        <Box className={classes.title}>Beliebte Filter</Box>
        <LinkButton
          label="Filter"
          testId="filters-open-filter-popup"
          onClick={handleFiltersModalOpen}
          labelClassName={classes.button}
          icon={<FilterIcon />}
        />
      </Box>
      <Box className={classes.chips}>
        {activeFiltersTitles.map((filter) => (
          <Chip
            key={filter.id}
            label={filter.title}
            className={clsx(classes.chip, {
              [classes.chipActive]: filter.isActive,
            })}
            data-testid={`top-filters-chip-${filter.id}`}
            onClick={() => handleChipClick(filter.id)}
          />
        ))}
      </Box>
    </Box>
  )
}
