import useSWRMutation from "swr/mutation"

import { IReview } from "../../../types"
import {
  IFetchWithConfigOptions,
  SWR_KEYS,
  fetchWithConfig,
} from "../../../utils/rest"
import { useRuntimeConfig } from "../../../hooks/runtimeConfig"

export interface IAdvisorResentReviewsRequestParams {
  limit?: number
  category_id?: number
}

export const useFetchMostRecentReviews = () => {
  const { getRuntimeConfig } = useRuntimeConfig()
  const {
    data: mostRecentReviews,
    trigger: getMostRecentReviews,
    error: mostRecentReviewsError,
    isMutating: isMostRecentReviewsLoading,
  } = useSWRMutation(
    SWR_KEYS.ADVISOR_REVIEWS_RECENT,
    async (
      url,
      {
        arg,
        headers,
      }: IFetchWithConfigOptions<IAdvisorResentReviewsRequestParams | undefined>
    ) => {
      return fetchWithConfig<{
        list: Array<IReview>
        count: number
        left: number
      }>(
        {
          url,
          method: "POST",
          data: arg,
          headers,
        },
        getRuntimeConfig
      )
    },
    {
      throwOnError: false,
    }
  )

  return {
    getMostRecentReviews,
    mostRecentReviews,
    mostRecentReviewsError,
    isMostRecentReviewsLoading,
  }
}
