const regExp = {
  email: /\S+@\S+\.\S+/,
  phone: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,10}$/,
  letterAndNumber: /^(?=.*[A-Za-z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u00FF])(?=.*\d)[A-Za-z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u00FF\d]{6,}$/,
  letterAndSpecialChar: /^(?=.*[A-Za-z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u00FF])(?=.*[`~!@#%^$*()\-_+=[\]{}\\|;:'",<.>/?&])[A-Za-z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u00FF`~!@#%^$*()\-_+=[\]{}\\|;:'",<.>/?&]{6,}$/,
  numberAndSpecialChar: /^(?=.*\d)(?=.*[`~!@#%^$*()\-_+=[\]{}\\|;:'",<.>/?&])[\d`~!@#%^$*()\-_+=[\]{}\\|;:'",<.>/?&]{6,}$/,
  letterNumberSpecialChar: /^(?=.*[A-Za-z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u00FF])(?=.*\d)(?=.*[`~!@#%^$*()\-_+=[\]{}\\|;:'",<.>/?&])[A-Za-z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u00FF\d`~!@#%^$*()\-_+=[\]{}\\|;:'",<.>/?&]{6,}$/,
}

export default regExp
