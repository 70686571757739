import { TInputError, TIndicator } from "./types"
import { strings } from "./strings"

export const inputErrorMessage: Record<TInputError, string> = {
  isRequired: strings.IS_REQUIRED,
  invalidPhoneOrEmail: strings.INVALID_PHONE_OR_EMAIL,
  invalidEmail: strings.INVALID_EMAIL,
  shortPassword: strings.SHORT_PASSWORD,
}

export const indicatorMessage: Record<TIndicator, string> = {
  short: strings.SHORT_PASSWORD,
  weak: strings.WEAK_PASSWORD,
  normal: strings.NORMAL_PASSWORD,
  strong: strings.STRONG_PASSWORD,
}

export const QUERY_TOKEN_VERIFICATION = "tokenVerification"
export const QUERY_PAYLOAD = "payload"
