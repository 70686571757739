import React, { useCallback, useEffect, useState } from "react"
import { useRouter } from "next/navigation"

import type { InputBaseComponentProps } from "@mui/material/InputBase"
import type { AutocompleteRenderInputParams } from "@mui/material"

import { Autocomplete, Popper } from "../../ui-lib/_common"
import { buildProfileUrl } from "../../utils/slugUtils"
import { Suggestion } from "./Suggestion"
import { IAdvisor } from "../../types"

interface ISearchSuggestionsProps {
  id: string
  inputComponent: (
    props: InputBaseComponentProps | AutocompleteRenderInputParams
  ) => React.ReactNode
  inputComponentType: "textField" | "baseInput"
  dropdownMode?: "fullscreen" | "normal"
  suggestions: IAdvisor[]
  value: string
  setValue: React.Dispatch<React.SetStateAction<string>>
  resetSearchState: () => void
  suggestionsDropdownClass?: string
  containerClassName?: string
  inputProps?: InputBaseComponentProps
  toggleExtend?: (nextExtended: boolean) => void
  clearKey?: boolean
}

const SearchSuggestions: React.FC<ISearchSuggestionsProps> = ({
  suggestions,
  inputComponent,
  inputComponentType,
  id,
  value,
  setValue,
  containerClassName,
  dropdownMode,
  inputProps,
  toggleExtend,
  resetSearchState,
  clearKey = true,
  suggestionsDropdownClass,
}) => {
  const router = useRouter()
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const onInputChange = useCallback(
    (newValue: string) => {
      setValue(newValue)
    },
    [setValue]
  )

  const cleanUpInput = useCallback(() => {
    if (typeof toggleExtend === "function") {
      resetSearchState()
      toggleExtend(false)
    }

  }, [resetSearchState, toggleExtend])

  const onInputBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      cleanUpInput()

      setIsDropdownOpen(false)
      if (typeof inputProps?.onBlur === "function") inputProps.onBlur(e)
    },
    [cleanUpInput, inputProps]
  )

  const onInputFocus = useCallback(
    (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (typeof toggleExtend === "function") toggleExtend(true)

      if (typeof inputProps?.onFocus === "function") inputProps.onFocus(e)
      setIsDropdownOpen(true)
    },
    [inputProps, toggleExtend]
  )

  const onSuggestionSelected = useCallback(
    (data: IAdvisor) => {
      const link = buildProfileUrl(data.name, data.listing_number)
      router.push(link)
      setIsDropdownOpen(false)
      cleanUpInput()
    },
    [cleanUpInput, router]
  )

  useEffect(() => {
    if (dropdownMode !== "fullscreen") return
    const body = window.document.getElementsByTagName("body")[0]

    if (suggestions.length !== 0) body.style.overflowY = "hidden"
    else body.style.overflowY = "auto"
  }, [dropdownMode, suggestions.length])

  const defaultInputProps = {
    value,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
      onInputChange(event.target.value),
    onBlur: onInputBlur,
    onFocus: onInputFocus,
  }
  return (
    <Autocomplete
      key={clearKey.toString()}
      className={containerClassName}
      id={id}
      options={suggestions}
      getOptionLabel={(option) => {
        return option.name
      }}
      open={isDropdownOpen}
      renderInput={(params) => {
        const { InputLabelProps, InputProps, ...rest } = params
        if (inputComponentType === "baseInput") {
          return inputComponent({
            ...params.InputProps,
            ...rest,
            ...defaultInputProps,
          })
        }

        if (inputComponentType === "textField") {
          return inputComponent({
            ...params,
            inputProps: {
              ...params.inputProps,
              ...defaultInputProps,
            },
            InputProps: {
              ...params.InputProps,
              endAdornment: null,
            },
          })
        }
      }}
      renderOption={(props, option, state) => {
        // @ts-ignore
        const { key, ...restProps } = props
        return (
          <Suggestion
            key={option.id}
            rootProps={restProps}
            {...option}
            isHighlighted={state.selected}
          />
        )
      }}
      onChange={(_, suggestion, changeReason) => {
        if (suggestion && changeReason === "selectOption") {
          onSuggestionSelected(suggestion)
        }
      }}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      filterOptions={(options) => options}
      PopperComponent={(props) => {
        // hide no-options dropdown
        if (!suggestions.length || !value.length) {
          return null
        }

        // means that we have custom styling
        if (dropdownMode === "fullscreen") {
          return (
            <div className={suggestionsDropdownClass}>
              {props.children as React.ReactNode}
            </div>
          )
        }

        return <Popper {...props} />
      }}
    />
  )
}

export default SearchSuggestions
