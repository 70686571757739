import React, { useState } from "react"
import VisibilityIcon from "../../_common/QuesticoIcon/VisibilityIcon"
import VisibilityOffIcon from "../../_common/QuesticoIcon/VisibilityOffIcon"
import classes from "./VisibilityIcon.module.scss"

interface IProps {
  visibilityHandler: (isVisible: boolean) => void
}

const VisibilityIndicator: React.FC<IProps> = ({ visibilityHandler }) => {
  const [isVisible, setIsVisible] = useState(false)

  const clickHandler = () => {
    const newIsVisible = !isVisible
    setIsVisible(newIsVisible)
    visibilityHandler(newIsVisible)
  }

  return (
    <button
      className={classes.root}
      type="button"
      onClick={clickHandler}
      data-testid="password-eye-icon"
    >
      {isVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
    </button>
  )
}

export default VisibilityIndicator
