"use client"

import { useRouter, usePathname } from "next/navigation"
import { ParsedUrlQuery } from "querystring"
import {
  Dispatch,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react"
import { useSkipInitialRender } from "../../../../hooks/useSkipInitialRender"
import {
  FiltersModuleFieldId,
  IExpertsFilterHub,
  FiltersModuleMode,
} from "../../../../types"
import {
  flattenedURLSearchParams,
  isStrEqualToTrue,
  updateSearchParamsFromEntries,
} from "../../../../utils/utils"
import { DELIMITER } from "../consts"
import {
  FiltersActions,
  filtersReducer,
  IFiltersActions,
  IFiltersActionsPayload,
  initFiltersState,
} from "../reducers/filtersReducer"
import { useFiltersSelector } from "../selectors/filtersSelectors"
import { IFiltersState, IFilterValues } from "../types"
import {
  getDefaultFilterOption,
  hasValidOptionInQuery,
  isFullMode,
  isLimitedMode,
} from "../utils"
import { ReadonlyURLSearchParams } from "next/navigation"

interface IUseFiltersReducer {
  withQueryStringControl: boolean
  query: ReadonlyURLSearchParams
  mode: FiltersModuleMode
  expertsFilterHub: IExpertsFilterHub
}

export interface IFiltersReducer {
  state: IFiltersState
  dispatch: Dispatch<IFiltersActions>
  sanitizedQuery: ParsedUrlQuery
  layoutSettings: {
    /**
     * Based on contentful configuration settings
     */
    isFullMode: boolean
    /**
     * Based on contentful configuration settings
     */
    isLimitedMode: boolean
  }
  filtersState: IFilterValues
  applyFilter: () => void
  setFiltersState: (payload?: IFiltersActionsPayload) => void
}

/**
 * @note Price filter field transforms to maxPrice
 * query parameter. Custom logic for price filter present.
 */
export const getInitFiltersReducerState = ({
  expertsFilterHub,
  query,
  withQueryStringControl,
}: Omit<IUseFiltersReducer, "mode">): {
  initState: IFiltersState
  sanitizedQuery: ParsedUrlQuery
} => {
  const { filters, sorting: initSorting } = expertsFilterHub
  const sanitizedQuery = flattenedURLSearchParams(query)

  const sorting = initSorting
    ? {
        ...initSorting,
        defaultOption: getDefaultFilterOption(
          FiltersModuleFieldId.SORTING,
          initSorting.defaultOption,
          {},
          false
        ),
        initOption: getDefaultFilterOption(
          FiltersModuleFieldId.SORTING,
          initSorting.defaultOption,
          {},
          false
        ),
        expanded: false,
        enabled: initSorting.enabled,
        options: initSorting.options.map((option) => {
          const [name, id] = option.id.toString().split(DELIMITER)
          return {
            id,
            name,
            enabled: option.enabled,
          }
        }),
      }
    : null

  const initState = filters.reduce((acc, curr) => {
    const { id, defaultOption, options, enabled } = curr

    const getNameId = (input: string) => input.split(DELIMITER)

    const normalizedOptions = options.map((option) => {
      const [name, id] = getNameId(option.id.toString())

      return {
        id,
        name,
        enabled: option.enabled,
        children:
          option.children &&
          (option.children as string[]).map((child) => {
            const [childName, childId] = getNameId(child)
            return {
              id: childId,
              name: childName,
              enabled: true,
            }
          }),
      }
    })

    const hasValidOption = hasValidOptionInQuery(
      id,
      sanitizedQuery,
      normalizedOptions
    )

    const option = getDefaultFilterOption(
      id,
      defaultOption,
      sanitizedQuery,
      hasValidOption
    )

    const initContentfulOption = getDefaultFilterOption(
      id,
      defaultOption,
      {},
      false
    )

    if (!hasValidOption) {
      if (id === FiltersModuleFieldId.PRICE) delete sanitizedQuery.maxPrice
      else delete sanitizedQuery[id]
    }

    return {
      ...acc,
      filters: {
        ...acc.filters,
        [id]: {
          ...curr,
          defaultOption: withQueryStringControl ? option : initContentfulOption,
          initOption: initContentfulOption,
          expanded: false,
          enabled: hasValidOption || enabled,
          options: normalizedOptions,
        },
      },
      ids: [...acc.ids, id],
    }
  }, initFiltersState)

  return {
    initState: {
      ...initState,
      ...(sorting && { sorting }),
      search: (sanitizedQuery.search as string) || "",
      isFreeChecked: isStrEqualToTrue(sanitizedQuery.freeOnly),
    },
    sanitizedQuery,
  }
}

export const useFiltersReducer = ({
  expertsFilterHub,
  query,
  mode,
  withQueryStringControl,
}: IUseFiltersReducer): IFiltersReducer => {
  const { initState, sanitizedQuery } = getInitFiltersReducerState({
    expertsFilterHub,
    query,
    withQueryStringControl,
  })

  const isFiltersStateReady = useSkipInitialRender()

  const { replace } = useRouter()
  const pathname = usePathname()

  const [state, dispatch] = useReducer(filtersReducer, initState)

  const layoutSettings = useMemo(() => {
    return {
      isFullMode: isFullMode(mode),
      isLimitedMode: isLimitedMode(mode),
    }
  }, [mode])

  const {
    activeOptions,
    initOptions,
    filterOptionsForQuery,
  } = useFiltersSelector(state)

  const [filtersState, setFilters] = useState<IFilterValues>(activeOptions)

  /**
   * We don’t want to replace url when state is null or when
   * it derives state very first time (becoming not null).
   *
   * Why?
   * After contentful configuration comes, we don't want updating url with
   * filter query parameters initially, but only after applying filter.
   */
  useEffect(() => {
    if (!isFiltersStateReady) return
    const updatedQuery = {
      ...sanitizedQuery,
      ...filterOptionsForQuery,
      ...(state.search && { search: state.search }),
    }

    if (!state.search) {
      delete updatedQuery.search
    }

    // @ts-ignore
    delete updatedQuery.page
    const searchParams = updateSearchParamsFromEntries(undefined, updatedQuery)
    replace(`${pathname}?${searchParams}`)
  }, [state.search, filtersState])

  const applyFilter = useCallback(() => {
    dispatch({
      type: FiltersActions.SET_FILTERS_MODAL_OPEN,
      payload: {
        filtersModalOpen: false,
      },
    })

    setFilters(activeOptions)
  }, [activeOptions])

  const setFiltersState = useCallback(
    (payload?: IFiltersActionsPayload) => {
      dispatch({
        type: FiltersActions.RESET_FILTERS,
        payload: payload || {},
      })

      setFilters(initOptions)
    },
    [initOptions]
  )

  return {
    state,
    dispatch,
    sanitizedQuery,
    layoutSettings,
    filtersState,
    applyFilter,
    setFiltersState,
  }
}
