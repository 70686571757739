import React from "react"
import clsx from "clsx"
import { IWithClassName } from "./types"
import classes from "./IconWrapper.module.scss"

const IconWrapper: React.FC<IWithClassName> = ({ children, className }) => {
  return <i className={clsx(classes.root, className)}>{children}</i>
}

export default IconWrapper
