"use client"

import React, { ComponentProps } from "react"
import dynamic from "next/dynamic"

const HoroscopeContentComponent = dynamic(() =>
  import("./HoroscopeContentComponent")
)

const LazyHoroscopeContentComponent: React.FC<ComponentProps<
  typeof HoroscopeContentComponent
>> = (props) => {
  return <HoroscopeContentComponent {...props} />
}

export default LazyHoroscopeContentComponent
