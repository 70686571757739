import React, { useCallback } from "react"
import { useTranslations } from "next-intl"
import { useRouter } from "next/navigation"

import { ClientTrackingEventName } from "../../types"
import { useTracking } from "../../hooks/tracking"
import SearchInput from "./SearchInput"
import SearchSuggestions from "./SearchSuggestions.connected"
import { AppRouterInstance } from "next/dist/shared/lib/app-router-context.shared-runtime"
import classes from "./Search.module.scss"

type ISearchProps = Pick<
  React.ComponentProps<typeof SearchInput>,
  "allowToCloseIfNoValue" | "toggleExtend" | "extended"
> &
  Pick<
    React.ComponentProps<typeof SearchSuggestions>,
    "containerClassName" | "dropdownMode"
  >

const REDIRECT_TO = "/search"
const INPUT_NAME = "search"

function validate(term: string) {
  return term !== "" && term.length >= 3
}

const doSearch = (term: string, router: AppRouterInstance) => {
  // we don't want to navigate anywhere in storybook environment
  if (process.env.STORYBOOK_ENV) {
    console.warn(
      `IN STORYBOOK: No custom callback provided. Skipping navigating to "${REDIRECT_TO}" for search term "${term}"`
    )
    return
  }
  router.push(`${REDIRECT_TO}?${INPUT_NAME}=${term}`)
}

const Search: React.FC<ISearchProps> = ({
  extended,
  toggleExtend,
  containerClassName,
  allowToCloseIfNoValue,
  dropdownMode,
}) => {
  const router = useRouter()
  const translate = useTranslations()
  const { trackEvent: trackCustomEvent } = useTracking()

  const onSearchCallback = useCallback(
    (term: string) => {
      const isValid = validate(term)

      if (!isValid) return

      trackCustomEvent({
        eventName: ClientTrackingEventName.PRODUCT_SEARCH,
        properties: {
          query: term,
          source: "navigation",
          suggestions: false,
        },
      })

      doSearch(term, router)

      const activeEl = window.document.activeElement as HTMLElement
      if (activeEl) {
        activeEl.blur()
      }
    },
    [router, trackCustomEvent]
  )

  return (
    <SearchSuggestions
      id="navigation"
      onSearchSubmit={onSearchCallback}
      containerClassName={containerClassName}
      toggleExtend={toggleExtend}
      inputComponent={({ inputProps, loading, resetSearchState }) => {
        return (
          <SearchInput
            extended={extended}
            toggleExtend={toggleExtend}
            inputProps={inputProps}
            loading={loading}
            resetSearchState={resetSearchState}
            allowToCloseIfNoValue={allowToCloseIfNoValue}
            name={INPUT_NAME}
            placeholder={translate("searchBeraterPlaceHolder")}
            testId="search-input"
          />
        )
      }}
      suggestionsDropdownClass={classes.suggestionsDropdownClass}
      dropdownMode={dropdownMode}
      inputComponentType="baseInput"
    />
  )
}

export default Search
