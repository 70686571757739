import React from "react"
import IconBase from "./IconBase"
import { IIconProps } from "./types"

const FilterIcon: React.FC<IIconProps> = ({ color = "currentColor" }) => (
  <IconBase>
    <svg width="100%" height="100%" viewBox="0 0 19 12">
      <path
        d="M17.5956 7.78455H4.9853C4.57823 6.80521 3.66649 6.23438 2.60624 6.23438C1.16686 6.23438 0 7.39984 0 8.84112C0 10.2824 1.16684 11.4466 2.60624 11.4466C3.6832 11.4466 4.60747 10.7226 5.00469 9.85515H17.5956C18.1426 9.85515 18.586 9.39637 18.586 8.81985C18.586 8.24339 18.1426 7.78455 17.5956 7.78455ZM2.60979 9.67794C2.14919 9.67794 1.77579 9.30406 1.77579 8.84285C1.77579 8.38164 2.14919 8.00776 2.60979 8.00776C3.07039 8.00776 3.44379 8.38164 3.44379 8.84285C3.44379 9.30406 3.07039 9.67794 2.60979 9.67794Z"
        fill={color}
      />
      <path
        d="M1.0066 3.75528H6.87571C7.27357 4.62269 8.1993 5.34298 9.27799 5.34298C10.3567 5.34298 11.2824 4.62269 11.6803 3.75528H17.6087C18.1566 3.75528 18.6007 3.22669 18.6007 2.65003C18.6007 2.07334 18.1566 1.54478 17.6087 1.54478H11.6609C11.2532 0.677365 10.34 0 9.27802 0C8.21606 0 7.3029 0.677365 6.89516 1.54478H1.0066C0.458764 1.54478 0.0146484 2.07334 0.0146484 2.65003C0.0146764 3.22672 0.458792 3.75528 1.0066 3.75528Z"
        fill={color}
      />
    </svg>
  </IconBase>
)

export default FilterIcon
