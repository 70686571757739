import React from "react"
import { GridSize, GridSpacing } from "@mui/material/Grid"
import { Breakpoint } from "@mui/material/styles"

import { Grid } from "../_common/MaterialUI"

type TSizes = { [P in Breakpoint]?: GridSize }

interface ITwoColumnLayoutProps {
  sideColumn?: React.ReactNode | undefined
  mainColumn: React.ReactNode
  containerClassName?: string
  sideColumnClassName?: string
  mainColumnClassName?: string
  sideSizes?: TSizes
  mainSizes?: TSizes
  containerSpacing?: GridSpacing
}

const TwoColumnLayout: React.FC<ITwoColumnLayoutProps> = ({
  sideColumn = undefined,
  mainColumn,
  containerClassName,
  sideColumnClassName,
  mainColumnClassName,
  sideSizes = {
    xs: 12,
    sm: 4,
    md: 3,
  },
  mainSizes = {
    xs: 12,
    sm: 8,
    md: 9,
  },
  containerSpacing = 2,
}) => {
  const mainSizesFinal: TSizes = sideColumn
    ? mainSizes
    : {
        xs: 12,
      }

  if (!sideColumn && !mainColumn) return null

  return (
    <Grid
      container
      spacing={sideColumn ? containerSpacing : 0}
      className={containerClassName}
    >
      {sideColumn && (
        <Grid item {...sideSizes} className={sideColumnClassName}>
          {sideColumn}
        </Grid>
      )}
      <Grid item {...mainSizesFinal} className={mainColumnClassName}>
        {mainColumn}
      </Grid>
    </Grid>
  )
}

export default TwoColumnLayout
