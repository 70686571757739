import React, { useCallback, useEffect, useState } from "react"

import type { AutocompleteRenderInputParams } from "@mui/material"
import type { InputBaseComponentProps } from "@mui/material/InputBase"

import useDebounce from "../../hooks/useDebounce"
import SearchSuggestions from "./LazySearchSuggestions"
import { useFetchExperts } from "../Experts/ExpertsService"
import { useTracking } from "../../hooks/tracking"
import { ClientTrackingEventName } from "../../types"

interface IInputComponentRenderProps {
  inputProps: InputBaseComponentProps | AutocompleteRenderInputParams
  loading: boolean
  resetSearchState: () => void
}

interface ISearchSuggestionsConnectedProps
  extends Omit<
    React.ComponentProps<typeof SearchSuggestions>,
    | "loadSuggestions"
    | "suggestions"
    | "inputComponent"
    | "value"
    | "setValue"
    | "resetSearchState"
  > {
  inputComponent: (renderProps: IInputComponentRenderProps) => React.ReactNode
  onSearchSubmit: (value: string) => void
  formClassName?: string
}

const SearchSuggestionsConnected: React.FC<ISearchSuggestionsConnectedProps> = (
  props
) => {
  const { inputComponent, formClassName, onSearchSubmit, ...rest } = props

  const { trackEvent } = useTracking()

  // need to clear input https://stackoverflow.com/a/59845474
  const [clearKey, setIsClear] = useState(false)
  const [value, setValue] = useState("")
  const debouncedValue = useDebounce(value, 250)
  const { experts, loading } = useFetchExperts(
    "searchSuggestions",
    { size: 10, search: debouncedValue },
    debouncedValue.length < 3
  )

  useEffect(() => {
    if (experts.content.length > 0) {
      trackEvent({
        eventName: ClientTrackingEventName.PRODUCT_SEARCH,
        properties: {
          query: debouncedValue,
          results: experts.content,
          source: rest.id,
          suggestions: true,
        },
      })
    }
  }, [JSON.stringify(experts.content), rest.id, trackEvent])

  const resetSearchState = useCallback(() => {
    setIsClear(!clearKey)
    setValue("")
  }, [clearKey])

  const handleSearchSubmit = useCallback(
    (e: React.FormEvent<HTMLElement>) => {
      e.preventDefault()

      if (value.length > 0) {
        onSearchSubmit(value)
        setIsClear(!clearKey)
        setValue("")
      }
    },
    [onSearchSubmit, value]
  )

  return (
    <SearchSuggestions
      {...rest}
      resetSearchState={resetSearchState}
      inputComponent={(inputProps) => (
        <form
          className={formClassName}
          onSubmit={handleSearchSubmit}
          data-testid="search-form"
        >
          {inputComponent({ inputProps, loading, resetSearchState })}
        </form>
      )}
      suggestions={experts?.content || []}
      setValue={setValue}
      value={value}
      clearKey={clearKey}
    />
  )
}

export default SearchSuggestionsConnected
