import clsx from "clsx"
import React, { useContext } from "react"

import {
  FormControl,
  Select,
  MenuItem,
  FormLabel,
  ExpandMoreIcon,
} from "../../../_common"
import FiltersContext from "../context/FiltersContext"
import { useFilterByIdSelector } from "../selectors/filterByIdSelector"
import { IFilterProps } from "../types"
import classes from "./SelectFilter.module.scss"

export const SelectFilter: React.FC<IFilterProps> = ({
  filter,
  handleFilterValueChange,
  variant = "standard",
}) => {
  const anchorRef = React.useRef(null)

  const {
    state,
    layoutSettings: { isFullMode, isLimitedMode },
  } = useContext(FiltersContext)

  const { id, options, title, defaultOption } = filter

  const { isSorting } = useFilterByIdSelector(state, id)

  const formLabelClasses = clsx({
    [classes.labelLimited]: isLimitedMode,
    [classes.labelFull]: isFullMode,
    [classes.labelFullSorting]: isFullMode && isSorting,
  })

  const wrapperClasses = clsx({
    [classes.wrapperFullSorting]: isFullMode && isSorting,
  })

  const filterClasses = clsx(classes.filter, {
    [classes.filterSorting]: isSorting,
    [classes.filterLimited]: isLimitedMode,
  })

  const selectIconClasses = clsx(classes.selectIcon, {
    [classes.selectIconLimited]: isLimitedMode,
    [classes.selectIconSorting]: isSorting,
  })

  const selectMenuClasses = clsx(classes.selectMenu, {
    [classes.selectMenuLimited]: isLimitedMode,
  })

  return (
    <FormControl
      fullWidth
      className={wrapperClasses}
      variant={variant}
      data-testid={`${id}-filter`}
    >
      <FormLabel className={formLabelClasses}>{title}</FormLabel>
      <Select
        id={`select-${id}`}
        ref={anchorRef}
        value={defaultOption}
        fullWidth
        classes={{
          select: classes.selectedOption,
          icon: selectIconClasses,
          iconOpen: classes.selectIconOpen,
        }}
        onChange={handleFilterValueChange}
        className={filterClasses}
        disableUnderline
        IconComponent={({ className }) => {
          return <ExpandMoreIcon className={className} />
        }}
        MenuProps={{
          classes: {
            paper: selectMenuClasses,
          },
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.id.toString()}
            value={option.id}
            data-testid={`${id}-filter-${option.id}-option`}
          >
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
