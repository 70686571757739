import clsx from "clsx"
import React, { useCallback, useContext, useMemo } from "react"
import { IFilterOption } from "../../../../types"

import { FormControl, ButtonGroup, Button } from "../../../_common"
import FiltersContext from "../context/FiltersContext"
import { IFilterProps } from "../types"
import { getProductTypeBtnLabel } from "../utils"
import classes from "./ButtonGroupFilter.module.scss"

/**
 * @todo: Define common ButtonGroup component.
 */
export const ButtonGroupFilter: React.FC<IFilterProps> = ({
  filter,
  handleFilterValueChange,
}) => {
  const { id, options, defaultOption } = filter

  const availableOptions = useMemo(
    () => options.filter((option) => option.enabled),
    [options]
  )

  const { isPageConfig } = useContext(FiltersContext)

  const handleButtonClick = useCallback(
    (value: IFilterOption["id"]) => {
      if (handleFilterValueChange) {
        handleFilterValueChange(value)
      }
    },
    [handleFilterValueChange]
  )

  if (!availableOptions.length) return null

  return (
    <FormControl className={classes.wrapper} data-testid={`${id}-filter`}>
      <ButtonGroup className={classes.group} size="medium">
        {options.map((option) => (
          <Button
            key={option.id}
            data-testid={`${id}-filter-${option.id}-option`}
            className={clsx(classes.button, {
              [classes.buttonActive]: defaultOption === option.id,
              [classes.pageConfig]: isPageConfig,
            })}
            onClick={() => handleButtonClick(option.id)}
          >
            {getProductTypeBtnLabel(option)}
          </Button>
        ))}
      </ButtonGroup>
    </FormControl>
  )
}
