export const makeAttributesObject = (
  dataAttributes: Record<string, string> | undefined
): Record<string, string> => {
  if (!dataAttributes) return {}

  return Object.keys(dataAttributes).reduce(
    (acc: Record<string, string>, key: string) => {
      acc[`data-${key}`] = dataAttributes[key]

      return acc
    },
    {}
  )
}
