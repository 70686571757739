import { useMemo } from "react"
import { FiltersModuleFieldId, IExpertsFilter } from "../../../../types"
import { IFiltersState } from "../types"
import { findOptionNameById } from "../utils"

interface IFilterByIdSelector {
  filter: IExpertsFilter
  isSorting: boolean
}

export const useFilterByIdSelector = (
  state: IFiltersState,
  id: FiltersModuleFieldId
): IFilterByIdSelector => {
  const { filters, sorting } = state

  const isSorting = id === FiltersModuleFieldId.SORTING

  const filter = isSorting ? sorting : filters[id]

  const { defaultOption, options } = filter!

  const defaultOptionTitle = useMemo(
    () => findOptionNameById(options, defaultOption),
    [defaultOption, options]
  )

  return {
    filter: { ...filter!, defaultOptionTitle },
    isSorting,
  }
}
