import React from "react"

import IconBase from "./IconBase"
import { IIconProps } from "./types"
import { useCheckBrand } from "./utils/useCheckBrand"

const SearchIcon: React.FC<IIconProps> = ({ color = "currentColor" }) => {
  const { isQuesticoBrand } = useCheckBrand()
  return (
    <IconBase>
      {isQuesticoBrand ? (
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.5723 19.1445C15.3066 19.1445 19.1445 15.3066 19.1445 10.5723C19.1445 5.83793 15.3066 2 10.5723 2C5.83793 2 2 5.83793 2 10.5723C2 15.3066 5.83793 19.1445 10.5723 19.1445Z"
            stroke={color}
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="square"
          />
          <path
            d="M10.5722 16.0082C7.5704 16.0082 5.13623 13.574 5.13623 10.5723"
            stroke={color}
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="square"
          />
          <path
            d="M16.6343 16.6343L22 22"
            stroke={color}
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="square"
          />
        </svg>
      ) : (
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.2 19.4C15.7287 19.4 19.4 15.7287 19.4 11.2C19.4 6.67126 15.7287 3 11.2 3C6.67126 3 3 6.67126 3 11.2C3 15.7287 6.67126 19.4 11.2 19.4Z"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6.2998 11.2C6.2998 8.50004 8.4998 6.30005 11.1998 6.30005"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M17.0601 17.0601L21.0001 21.0001"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </IconBase>
  )
}

export default SearchIcon
