import clsx from "clsx"
import React, { useEffect, useRef } from "react"
import {
  InputBaseComponentProps,
  InputBaseProps,
} from "@mui/material/InputBase"

import {
  IconButton,
  InputBase,
  InputAdornment,
  CircularProgress,
} from "../_common"
import CloseIcon from "../_common/QuesticoIcon/CloseIcon"
import SearchIcon from "../_common/QuesticoIcon/SearchIcon"
import classes from "./SearchInput.module.scss"

interface IEndAdornmentProps {
  showCloseButton: boolean
  onClose: () => void
  loading: boolean
}

const EndAdornment: React.FC<IEndAdornmentProps> = ({
  showCloseButton,
  loading,
  onClose,
}) => {
  let content = null

  if (loading) content = <CircularProgress color="inherit" size={18} />
  else if (showCloseButton) {
    content = (
      <IconButton
        className={classes.closeButton}
        onClick={onClose}
        id="close-search"
        size="large"
      >
        <CloseIcon />
      </IconButton>
    )
  }

  if (!content) return null

  return (
    <InputAdornment
      position="end"
      className={clsx(classes.adornment, classes.adornmentEnd)}
    >
      {content}
    </InputAdornment>
  )
}

interface ISearchInputProps {
  loading: boolean
  inputProps: InputBaseComponentProps
  resetSearchState?: () => void
  extended: boolean
  /**
   * forced to show close search button
   * even if there is no value
   *
   * false by default
   */
  allowToCloseIfNoValue?: boolean
  toggleExtend: (nextExtended: boolean) => void
  name: string
  placeholder: string
  testId: string
}

/**
 * @todo Move to ui-lib
 */
const SearchInput: React.FC<ISearchInputProps> = ({
  inputProps,
  loading,
  extended,
  toggleExtend,
  allowToCloseIfNoValue = false,
  name,
  resetSearchState,
  placeholder,
  testId,
}) => {
  const searchInputRef = useRef<HTMLInputElement>(null)

  const props = {
    ...inputProps,
    name,
    placeholder,
    inputProps: {
      ...inputProps.inputProps,
      "data-testid": testId,
    },
  } as InputBaseProps

  useEffect(() => {
    if (extended) searchInputRef.current?.focus()
  }, [extended])

  const inputClasses = clsx(classes.input, {
    [classes.inputExtended]: extended,
  })

  return (
    <InputBase
      {...props}
      classes={{
        root: classes.root,
        input: inputClasses,
      }}
      startAdornment={
        <InputAdornment
          position="start"
          className={clsx(classes.adornment, classes.adornmentStart)}
        >
          <IconButton
            className={classes.searchButton}
            size="medium"
            color="inherit"
            disableRipple
            tabIndex={-1}
            onClick={() => {
              toggleExtend(true)
            }}
            name="search-toggle"
          >
            <SearchIcon />
          </IconButton>
        </InputAdornment>
      }
      endAdornment={
        <EndAdornment
          loading={loading}
          showCloseButton={
            extended && (allowToCloseIfNoValue || !!props?.value)
          }
          onClose={() => {
            if (resetSearchState) {
              resetSearchState()
            }

            toggleExtend(false)
          }}
        />
      }
    />
  )
}

export default SearchInput
