import { useEffect } from "react"

import { useUnleash } from "../../../../hooks/useUnleash"
import {
  useFetchMostRecentReviews,
  MostRecentReviews,
  IAdvisorResentReviewsRequestParams,
} from "../../Reviews"
import { IListingIdentificator, IListingSlot } from "../../types"

interface IUseSlotsOptions {
  mrrConfig: IAdvisorResentReviewsRequestParams & {
    expertsLength: number
    listingId: IListingIdentificator
  }
}

const createMRRSlot = (
  mrrConfig: IUseSlotsOptions["mrrConfig"],
  mostRecentReviews: ReturnType<
    typeof useFetchMostRecentReviews
  >["mostRecentReviews"]
): IListingSlot | null => {
  if (mostRecentReviews && (mostRecentReviews?.list?.length || 0) > 4) {
    const putLast = mrrConfig.expertsLength > 0 && mrrConfig.expertsLength < 4
    return {
      position: putLast ? mrrConfig.expertsLength : 3,
      item: <MostRecentReviews reviews={mostRecentReviews?.list} />,
    }
  }

  return null
}

const checkCanFetchMrr = (
  mrrConfig: IUseSlotsOptions["mrrConfig"],
  { isEnabled }: ReturnType<typeof useUnleash>
) => {
  const userAllowedToSeeMrrs = isEnabled

  const slotConditionsMet =
    mrrConfig.listingId !== "alternative" && mrrConfig.expertsLength > 0

  const shouldFetchMrr = userAllowedToSeeMrrs && slotConditionsMet

  return shouldFetchMrr
}

export const useCreateListingSlots = ({
  mrrConfig,
}: IUseSlotsOptions): IListingSlot[] => {
  const mrrFeatureFlag = useUnleash("feat.customer.MRR")

  const {
    getMostRecentReviews,
    mostRecentReviews,
  } = useFetchMostRecentReviews()

  const canFetchMrr = checkCanFetchMrr(mrrConfig, mrrFeatureFlag)

  useEffect(() => {
    if (canFetchMrr) {
      getMostRecentReviews({
        category_id: mrrConfig.category_id,
        limit: mrrConfig.limit,
      })
    }
  }, [
    getMostRecentReviews,
    mrrConfig.limit,
    canFetchMrr,
    mrrConfig.category_id,
  ])

  const slots: IListingSlot[] = []

  const mrrSlot = createMRRSlot(mrrConfig, mostRecentReviews)

  if (mrrSlot) slots.push(mrrSlot)

  return slots
}
