"use client"

import React, { ComponentProps } from "react"
import dynamic from "next/dynamic"

const ContentItemListComponent = dynamic(() =>
  import("./ContentItemListComponent")
)

const LazyContentItemListComponent: React.FC<ComponentProps<
  typeof ContentItemListComponent
>> = (props) => {
  return <ContentItemListComponent {...props} />
}

export default LazyContentItemListComponent
