"use client"

import React, { ReactNode, useMemo } from "react"
import { useSearchParams } from "next/navigation"
import FiltersContext from "./FiltersContext"
import { useFiltersReducer } from "../hooks/useFiltersReducer"
import { IExpertsFilterHub, FiltersModuleMode } from "../../../../types"

interface IFiltersProviderProps {
  freeOnly: boolean
  mode: FiltersModuleMode
  redirect?: string
  withSearching: boolean
  withQueryStringControl: boolean
  expertsFilterHub: IExpertsFilterHub
  isPageConfig: boolean
  children?: ReactNode
}

/**
 * @todo: Refactor ExpertsConnected
 *
 * https://adviqo.atlassian.net/browse/MISSION-2317
 */
const FiltersProvider: React.FC<IFiltersProviderProps> = ({
  freeOnly,
  mode,
  redirect,
  withSearching,
  withQueryStringControl,
  expertsFilterHub,
  isPageConfig,
  children,
}) => {
  const searchParams = useSearchParams()

  const {
    state,
    dispatch,
    layoutSettings,
    applyFilter,
    filtersState,
    setFiltersState,
  } = useFiltersReducer({
    expertsFilterHub,
    query: searchParams,
    mode,
    withQueryStringControl,
  })

  const providerValue = useMemo(
    () => ({
      state,
      dispatch,
      layoutSettings,
      redirect,
      applyFilter,
      filtersState,
      setFiltersState,
      isPageConfig,
      withSearching,
      freeOnly,
    }),
    [
      dispatch,
      layoutSettings,
      redirect,
      applyFilter,
      filtersState,
      setFiltersState,
      state,
      isPageConfig,
      withSearching,
      freeOnly,
    ]
  )

  return (
    <FiltersContext.Provider value={providerValue}>
      {children}
    </FiltersContext.Provider>
  )
}

export default FiltersProvider
