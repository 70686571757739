import React from "react"
import FiltersModule from "../../ui-lib/Experts/FiltersModule"
import FiltersProvider from "../../ui-lib/Experts/Filters/context/FiltersProvider"
import { IExpertsListingWithFilters } from "../../graphql/contentful-schema"

type IExpertsListingComponentProps = IExpertsListingWithFilters & {
  isPageConfig?: boolean
}

const ExpertsListingComponent: React.FC<{
  data: IExpertsListingComponentProps
}> = ({
  data: {
    freeOnly,
    withQueryStringControl,
    expertsFilterHub,
    isPageConfig = false,
    mode,
    withSearching,
    redirect,
    sys,
    display,
    size,
    withPagination,
    includeListingNos,
    emptyState,
    expertListHeadline,
    listingName,
  },
}) => {
  return (
    <FiltersProvider
      freeOnly={freeOnly}
      withQueryStringControl={withQueryStringControl}
      expertsFilterHub={expertsFilterHub}
      isPageConfig={isPageConfig}
      mode={mode}
      withSearching={withSearching}
      {...(redirect && { redirect })}
    >
      <FiltersModule
        sys={sys}
        display={display}
        size={size || 3}
        withPagination={withPagination}
        {...(includeListingNos && { includeListingNos })}
        {...(emptyState && { emptyState })}
        {...(expertListHeadline && { expertListHeadline })}
        {...(listingName && { listingName })}
      />
    </FiltersProvider>
  )
}

export default ExpertsListingComponent
