"use client"

import Slider from "react-slick"
import clsx from "clsx"
import { useEffect } from "react"

import { ClientTrackingEventName, IReview } from "../../../types"
import { useTracking } from "../../../hooks/tracking"
import { MostRecentReviewEntry } from "./ReviewEntry"

import classes from "./MostRecentReviews.module.scss"

interface IMostRecentReviewsProps {
  /**
   * list of reviews
   */
  reviews: IReview[]
}

const settigns = {
  arrows: false,
  autoplay: true,
  dots: true,
  dotsClass: clsx("slick-dots", classes.dots),
  easing: "ease-in",
  centerMode: false,
  pauseOnHover: true,
  slidesToShow: 1.3,
  className: classes.sliderContainer,
  slidesToScroll: 1,
  speed: 500,
  autoplaySpeed: 7000,
  variableWidth: true,
  responsive: [
    {
      breakpoint: 475,
      settings: {
        slidesToShow: 1.1,
      },
    },
  ],
}

const MostRecentReviews = ({ reviews }: IMostRecentReviewsProps) => {
  const { trackEvent } = useTracking()

  useEffect(() => {
    trackEvent({
      eventName: ClientTrackingEventName.REVIEW_LIST_VIEWED,
      properties: {
        reviews: reviews.map((review, index) => ({
          rating: review.rating,
          text: review.text,
          name: review.advisor_name,
          position: index,
        })),
      },
    })
  }, [reviews, trackEvent])

  return (
    <div className={classes.root}>
      {/* @ts-ignore 'Slider' cannot be used as a JSX component. */}
      <Slider {...settigns} infinite={reviews.length > 1}>
        {reviews.map((review, index) => (
          <MostRecentReviewEntry key={review.id} {...review} position={index} />
        ))}
      </Slider>
    </div>
  )
}

export default MostRecentReviews
