import React, { ComponentProps } from "react"
import dynamic from "next/dynamic"

const ContentItemComponent = dynamic(() => import("./ContentItemComponent"))

const LazyContentItemComponent: React.FC<ComponentProps<
  typeof ContentItemComponent
>> = (props) => {
  return <ContentItemComponent {...props} />
}

export default LazyContentItemComponent
