import React, { useCallback } from "react"
import { format } from "date-fns"
import Image from "next/image"
import clsx from "clsx"

import { buildProfileUrl } from "../../../utils/slugUtils"
import { useTracking } from "../../../hooks/tracking"
import { ClientTrackingEventName, IReview } from "../../../types"
import { Box, AdviqoLink, Typography } from "../../_common"
import { StarRating } from "../Rating"
import classes from "./ReviewEntry.module.scss"

const Title = ({ children }: { children: string }) => (
  <Typography className={classes.subject} component="h5" variant="h5">
    <Box>{children}</Box>
  </Typography>
)

const Comment = ({ children, mrr }: { children: string; mrr: boolean }) => (
  <Typography
    component="p"
    color="textSecondary"
    className={clsx(classes.text, mrr && classes.textMrr)}
  >
    {children}
  </Typography>
)

const ReviewDate = ({
  created_at,
  formatPattern,
}: {
  created_at: string
  formatPattern: string
}) => (
  <Typography component="div" color="textSecondary">
    Bewertung vom
    <Typography
      component="span"
      className={classes.date}
      color="textPrimary"
    >{` ${format(new Date(created_at), formatPattern)}`}</Typography>
  </Typography>
)

interface IMostRecentReviewEntryProps extends IReview {
  position: number
}

export const MostRecentReviewEntry: React.FC<IMostRecentReviewEntryProps> = ({
  author,
  rating,
  text,
  created_at,
  title,
  advisor_avatar,
  advisor_name,
  listing_number,
  position,
}) => {
  const { trackEvent } = useTracking()

  const handleReviewClick = useCallback(() => {
    trackEvent({
      eventName: ClientTrackingEventName.REVIEW_CLICKED,
      properties: {
        rating,
        text,
        position,
      },
    })
  }, [position, rating, text, trackEvent])

  return (
    <Box className={clsx(classes.root, classes.rootMrr)}>
      <div className={classes.header}>
        <span className={classes.name}>{author}</span>
        {advisor_name !== null && Boolean(advisor_name) && (
          <div className={classes.advisor}>
            {advisor_avatar !== null ? (
              <Image
                className={classes.avatar}
                src={advisor_avatar}
                alt=""
                width={24}
                height={24}
              />
            ) : null}
            <AdviqoLink
              onClick={handleReviewClick}
              className={classes.advisorLink}
              to={`${buildProfileUrl(advisor_name, listing_number)}#reviews`}
            >
              mit {advisor_name}
            </AdviqoLink>
          </div>
        )}
        <Box className={clsx(classes.rating, classes.ratingMrr)}>
          <StarRating
            rating={{
              average: Number(rating),
              average_rounded: Number(rating),
              count: 0,
            }}
            withDot={false}
          />
        </Box>
      </div>
      {title && <Title>{title}</Title>}
      {text && <Comment mrr>{text}</Comment>}
      <div className={classes.footer}>
        {Boolean(created_at) && (
          <Box className={classes.meta}>
            <ReviewDate
              created_at={created_at}
              formatPattern="dd.MM.yyyy hh:mm"
            />
          </Box>
        )}
      </div>
    </Box>
  )
}

export const ReviewEntry: React.FC<IReview> = ({
  author,
  rating,
  text,
  created_at,
  title,
}) => {
  return (
    <Box className={classes.root}>
      {title && <Title>{title}</Title>}
      {text && <Comment mrr={false}>{text}</Comment>}
      <div className={classes.footer}>
        <Typography className={classes.name} component="h6">
          {author}
          <Box className={classes.rating}>
            <StarRating
              rating={{
                average: Number(rating),
                average_rounded: Number(rating),
                count: 0,
              }}
              withDot={false}
            />
          </Box>
        </Typography>
        {Boolean(created_at) && (
          <Box className={classes.meta}>
            <ReviewDate created_at={created_at} formatPattern="dd.MM.yyyy" />
          </Box>
        )}
      </div>
    </Box>
  )
}
